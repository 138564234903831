import React from "react";

const StarIcon = (props) => {
  return (
    <>
      <svg
        width="80"
        height="79"
        viewBox="0 0 80 79"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M75.0733 30.8673C74.6982 29.7173 73.9933 28.7029 73.0463 27.9501C72.0993 27.1974 70.952 26.7395 69.747 26.6334L52.1572 25.1121L45.2755 8.71339C44.8021 7.5982 44.011 6.647 43.0008 5.97834C41.9905 5.30968 40.8058 4.95312 39.5943 4.95312C38.3828 4.95312 37.1981 5.30968 36.1879 5.97834C35.1776 6.647 34.3865 7.5982 33.9131 8.71339L27.0222 25.1121L9.43236 26.6334C8.22078 26.7337 7.06582 27.1896 6.11238 27.9439C5.15895 28.6982 4.44951 29.7173 4.07308 30.8732C3.69666 32.0292 3.67001 33.2706 3.99649 34.4417C4.32297 35.6127 4.98802 36.6613 5.90822 37.4558L19.2765 49.1207L15.2648 66.476C14.9897 67.6578 15.069 68.8944 15.4929 70.0314C15.9167 71.1683 16.6663 72.1551 17.648 72.8684C18.6296 73.5816 19.7997 73.9897 21.012 74.0416C22.2243 74.0935 23.425 73.7869 24.464 73.1601L39.5851 63.9764L54.7061 73.1601C55.7451 73.7859 56.9454 74.0917 58.1571 74.0393C59.3688 73.987 60.5383 73.5787 61.5193 72.8656C62.5004 72.1526 63.2496 71.1662 63.6734 70.0298C64.0972 68.8934 64.1768 67.6573 63.9022 66.476L59.8905 49.1207L73.2588 37.4558C74.1765 36.6584 74.8383 35.6077 75.1612 34.4356C75.484 33.2635 75.4534 32.0222 75.0733 30.8673ZM54.4747 44.0196C53.6284 44.7559 52.9988 45.7089 52.6535 46.7763C52.3083 47.8436 52.2604 48.9848 52.5151 50.0773L56.03 65.2971L42.779 57.249C41.8167 56.663 40.7117 56.3531 39.5851 56.3531C38.4584 56.3531 37.3534 56.663 36.3911 57.249L23.1401 65.2971L26.655 50.0773C26.9097 48.9848 26.8619 47.8436 26.5166 46.7763C26.1713 45.7089 25.5417 44.7559 24.6954 44.0196L12.9411 33.765L28.414 32.4257C29.5344 32.3298 30.6071 31.9283 31.5152 31.2651C32.4233 30.6018 33.1321 29.7021 33.5644 28.664L39.5851 14.3174L45.6057 28.664C46.038 29.7021 46.7468 30.6018 47.6549 31.2651C48.563 31.9283 49.6357 32.3298 50.7561 32.4257L66.229 33.765L54.4747 44.0196Z"
          fill="#484848"
        />
      </svg>
    </>
  );
};

export default StarIcon;
