import CryptoJS from "crypto-js";
import { Envexports } from "@exports/Envexports";
import { atob, btoa } from "js-base64";
const ENCRYPTION_KEY = Envexports.encryptionkey;

export const encryptData = (data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    ENCRYPTION_KEY
  ).toString();
  return encrypted;
};

export const decryptData = (encryptedData) => {
  if (!encryptedData || encryptedData.trim() === "") {
    return null; // or handle the empty case appropriately
  }
  const decrypted = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};

export const base64Encrypt = (data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    ENCRYPTION_KEY
  ).toString();
  const base64encrypt = btoa(encrypted);
  return base64encrypt;
};

export const base64Decrypt = (encryptedData) => {
  if (!encryptedData || encryptedData.trim() === "") {
    return null;
  }
  const base64decrypt = atob(encryptedData);
  const decrypted = CryptoJS.AES.decrypt(base64decrypt, ENCRYPTION_KEY);
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};
