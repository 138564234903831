import React from "react";

const BellIcon = (props) => {
  return (
    <>
      <svg
        width="79"
        height="79"
        viewBox="0 0 79 79"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M39.441 0C43.7425 0 47.2264 3.5471 47.2264 7.9237L47.1909 8.56755C50.6195 9.95795 53.9335 12.2647 56.8723 15.3221C60.2772 18.8494 62.268 23.5262 62.8803 29.5263V51.1644L67.5847 59.4515C69.1647 62.0821 69.6703 64.2862 68.4379 66.1941C67.3082 67.9361 65.2898 68.5443 62.5524 68.4969H52.0059C50.7735 74.497 45.5516 79 39.3027 79C33.0459 79 27.828 74.497 26.5995 68.5009H14.9233L14.7021 68.4891C12.2255 68.2758 10.3808 67.0789 9.96607 64.7761C9.68562 63.2079 10.0569 61.5252 11.0247 59.5186L15.8634 50.9076L15.8753 29.2892C16.2979 24.5098 18.0873 20.0581 21.1999 15.9738C23.8345 12.5176 27.5989 9.97375 31.6832 8.5083C31.667 8.31386 31.6591 8.11881 31.6595 7.9237C31.6595 3.5471 35.1434 0 39.441 0ZM46.6221 68.5009H31.9834C33.0696 71.5424 35.9334 73.7149 39.3027 73.7149C42.6681 73.7149 45.5358 71.5424 46.6221 68.5009ZM39.8241 12.3398C34.4758 12.3398 28.5943 14.8875 25.3 19.2128C22.7799 22.515 21.3737 26.0147 21.0537 29.5223V51.6028C21.0537 52.065 20.9352 52.5192 20.7101 52.9182L15.6106 61.9874C15.413 62.3804 15.2542 62.7919 15.1366 63.2158H62.5919C63.0699 63.2237 63.4333 63.2039 63.6821 63.196C63.5167 62.8368 63.3307 62.4873 63.1252 62.1493L58.0415 53.1947C57.813 52.7911 57.6933 52.3351 57.6939 51.8714L57.7058 29.8067C57.2278 25.2168 55.7071 21.6539 53.1672 19.0153C49.0592 14.7493 44.2323 12.3398 39.8241 12.3398ZM39.441 5.28115C38.8647 5.28513 38.306 5.47994 37.8522 5.83513C37.3984 6.19032 37.075 6.68586 36.9327 7.2443C37.9044 7.12185 38.8682 7.05865 39.8241 7.05865C40.5233 7.05865 41.2303 7.09815 41.9374 7.18505C41.7846 6.63957 41.4583 6.15869 41.0079 5.81518C40.5575 5.47166 40.0074 5.28419 39.441 5.28115Z"
          fill="#484848"
        />
      </svg>
    </>
  );
};

export default BellIcon;
