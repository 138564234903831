import { Autocomplete, Box, MenuItem, TextField } from "@mui/material";
import React ,{useState, useRef, useEffect, useCallback} from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { capitalizeletter } from "../helpers/Helperfunctions";
import { style_exports } from "@exports/style_exports";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Customizedinput from "@components/Customizedinput";
import { get_equiments_brands_list } from "../services/Userservices";
import _ from 'lodash';
import { get_equiments_dropdown, getequipment_models } from "../services/Commonservices";
const CustomSelectsearchscroll = (props) => {
    const {
        type,
        display_name,
        onChange,
        capitalize,
        value,
        placeholder,
        dynamic_val,
        bg_color,
        endpoint,
        brandid,
        equipmentid,
        ...otherprops
      } = props;
    const containerRef = useRef(null);
    const initialLoad = useRef(false);
    const initialRenderRef = useRef(true);

/**--------------------------------------------------States Declaration and Initialization------------------------------------------ */
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  // const hasMore = useRef(true);
  const [page, setPage] = useState(1);
  const [pageSize,setPageSize] = useState(10)
  const [loading, setLoading] = useState(false);
  const [totalItemslength , setTotalItemslength] = useState(0);
  const [searchstr, setSearchstr] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [debouncedSearchStr, setDebouncedSearchStr] = useState("");

  /**-------------------------------------------------Functions------------------------------------------------------- */
  
  const fetchapidata = async({pagenumber} ={})=>{
    try{
      let res;
      const pageNumber = pagenumber ? pagenumber : page;
      const payload = {
        min: (pageNumber - 1) * pageSize,
        max:pageSize,
        searchQuery:searchstr
      }
      switch(endpoint){
        case "getEquipmentBrands":
          res = await get_equiments_brands_list(payload);
          return res;
        case "getEquipmentList":
          payload.brandId = brandid;
          res = await get_equiments_dropdown(payload);
          return res;
        case "getEquipmentModels":
          
          payload.brandId = brandid;
          payload.equipmentId = equipmentid ?? 0; 
          res = await getequipment_models(payload);
          return res
        default:
          break
      }
    }
    catch(err){
      console.log(err)
    }
  }
  
  const loadMoreItems = async({pagenumber,hasmore }={}) => {
    const hasmoreitems = hasmore ? hasmore :hasMore;
    if ((!hasmoreitems) || loading) return;
    if(equipmentid === null)return
    setLoading(true);
    const pageNumber = pagenumber ? pagenumber :page;
    setPage(pageNumber);
    try{
    // Simulate an API call to fetch data
    const res = await fetchapidata({pagenumber:pageNumber})
    if(res?.status){
      setTotalItemslength(res?.totalCount);
      // Capture current scroll position
      const container = containerRef.current;
      let prevScrollHeight;
      if(container){
         prevScrollHeight = container.scrollHeight ?? 0;
      }  
      if(pageNumber === 1){
        setItems(res?.data)
      }
      else{
        setItems((prevItems) => ([...prevItems, ...res?.data]));
      }
       // Update scroll position
       setTimeout(() => {
          if (container) {
            containerRef.current.scrollTop = prevScrollHeight;
          }
        }, 0);
        setLoading(false);
      if (res?.totalCount === items?.length) {
        // console.log("equal")
        setHasMore(false);
        // hasMore.current = false;
      }
      setPage((prevPage) => prevPage + 1);
    }
    else{
      // console.log(res?.message);
      setLoading(false);
    }
}
catch(err){
  console.log(err);
  setLoading(false);

}
  };

  

  const handleScroll = useCallback(
    _.debounce((event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      const scrollTolerance = 20;
      const scrollDifference = scrollHeight - scrollTop - clientHeight;
      if (scrollDifference <= scrollTolerance && !loading && hasMore && items?.length < totalItemslength) {
        loadMoreItems();
      }
    }, 200),
    [loading, hasMore]
  );
  const handleSelect = (event, value) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value, dynamic_val, props?.name);
    }
  };
  let capitalizedDisplayvalue = '';
  if (selectedValue !== undefined && selectedValue !== null && value) {
    if (type === 2 && selectedValue[display_name] !== undefined) {
      capitalizedDisplayvalue = {
        ...selectedValue,
        [display_name]: selectedValue[display_name],
      };
    } else if (type !== 2 && selectedValue) {
      capitalizedDisplayvalue = selectedValue;
    }
  }
  useEffect(() => {
    if (!initialLoad.current && brandid !==null) {
      loadMoreItems();
      initialLoad.current = true;
    }
  
  }, []);
  useEffect(()=>{
    if(value){
      setSelectedValue(value)
    }
  },[value])

  useEffect(()=>{
    setHasMore(true);
    // hasMore.current = true;
    if(brandid){  
      setItems([]);
      // console.log(brandid,"banrdug")
      loadMoreItems({pagenumber :1,hasmore:true});
    }
  },[brandid,equipmentid])

  useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      setDebouncedSearchStr(searchstr)
    }, 1000);
  return () => clearTimeout(delayDebounceFn);
  }, [searchstr]);

  useEffect(()=>{
    if (debouncedSearchStr) {
      // console.log("calling")
      setPage(1);
      setItems([]);
      setHasMore(true);
      // hasMore.current = true;
      loadMoreItems({ pagenumber: 1 });
    }
  },[debouncedSearchStr])
  return (
    <>
    <Autocomplete
      sx={{
        ".MuiAutocomplete-popupIndicator": {
          color: "#04BEF8", 
        },
      }}
      popupIcon={<KeyboardArrowDownIcon />}
      disableClearable
      options={items}
      value={capitalizedDisplayvalue ?? ""}
      // getOptionLabel={
      //   type === 2
      //     ? (option) => {return option[display_name] ?? ""}
      //     : (option) => option ?? " "
      // }
      getOptionLabel={(option) => {
        switch(type){
          case 1:
            return option ?? "";
          case 2:
            return option[display_name] ?? "";
          default:
            return '';
        }
      }}
      {...otherprops}
      onChange={handleSelect}
      renderInput={(params) => (
        <Customizedinput
          {...params}
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: bg_color
                ? bg_color
                : style_exports.grey_color,
            },
            "& .MuiInputBase-input": {
              // textTransform: capitalize ?"uppercase" :"none",
            },
          }}
          inputProps={{
            ...params.inputProps,
            value: capitalizeletter(params.inputProps.value || ""),
          }}
          fullWidth
          placeholder={placeholder ?? "Select"}
          onChange={(e) => {
            setSearchstr(e.target.value);
            if(type === 1){
              setSelectedValue(e.target.value);
            }
            else{
              setSelectedValue((prev)=>({...prev,[display_name]:e.target.value}))
            }
          }}
        />
      )}
      PaperComponent={(paperProps) => (
        <Box
          {...paperProps}
          onScroll={handleScroll}
          ref={containerRef}
          style={{ maxHeight: 100, overflowY: "scroll" }}
          sx={{
            "& .MuiAutocomplete-listbox": {
              maxHeight: "fit-content!important",
              backgroundColor:'white'
            },
            backgroundColor:'white!important',
            boxShadow: `rgba(0, 0, 0, 0.16) 0px 1px 4px`,
            borderRadius:'0.5rem'
          }}
        >
          {paperProps.children}
          {loading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={2}
            >
              <CircularProgress size={24} />
            </Box>
          )}
        </Box>
      )}
    />
    </>
  );
};

export default CustomSelectsearchscroll;
