import React from "react";
import Customizedinput from "@components/Customizedinput";
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { style_exports } from "@exports/style_exports";
import { capitalizeletter } from "../helpers/Helperfunctions";
const Customizedselectsearch = (props) => {
  const {
    type,
    options,
    display_name,
    onChange,
    capitalize,
    value,
    placeholder,
    dynamic_val,
    bg_color,
    ...otherprops
  } = props;
  const handleSelect = (event, value) => {
    // Capitalize the value
    // const capitalizedValue =
    //   type === 2
    //     ? { ...value, [display_name]: capitalizeletter(value[display_name]) }
    //     : capitalizeletter(value);
    const selectval = value;
    // If you need to pass the selected value to a parent component
    if (onChange) {
      onChange(selectval, dynamic_val, props?.name);
    }
  };
  let capitalizedDisplayvalue ="";
  if(value !== undefined && value !== null && value){
    if(type === 2 && value[display_name]!==undefined){
      capitalizedDisplayvalue = { ...value, [display_name]: capitalizeletter(value[display_name]) };
    }
    else if(type!==2 && value){
      capitalizedDisplayvalue = capitalizeletter(value);
    }
  }
  return (
    <>
      {typeof value !== undefined && (
        <Autocomplete
          sx={{
            ".MuiAutocomplete-popupIndicator": {
              color: "#04BEF8", // Customize the color of the dropdown icon
            },
          }}
          popupIcon={<KeyboardArrowDownIcon />}
          disableClearable
          options={options}
          value={capitalizedDisplayvalue ?? ""}
          getOptionLabel={
            type === 2
              ? (option) => option[display_name] ?? ""
              : (option) => option ?? " "
          }
          {...otherprops}
          onChange={handleSelect}
          renderInput={(params) => (
            <Customizedinput
              {...params}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: bg_color
                    ? bg_color
                    : style_exports.grey_color,
                },
                "& .MuiInputBase-input": {
                  // textTransform: capitalize ?"uppercase" :"none",
                },
              }}
              inputProps={{
                ...params.inputProps,
                value: capitalizeletter(params.inputProps.value || ""),
              }}
              fullWidth
              placeholder={placeholder ?? "Select"}
            />
          )}
        />
      )}
    </>
  );
};

export default Customizedselectsearch;
