import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Skeleton,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { artistCardStyles } from "@styles/CardStyles";

const ArtistsCardSkeleton = () => {
  const styles = artistCardStyles();
  return (
    <Card className={styles.cardContainer}>
      <Grid position={"relative"}>
        {/* Skeleton for the card image */}
        <Skeleton
          variant="rectangular"
          className={styles.cardImage}
          width="100%"
          height={200}
        />

        {/* Skeleton for the card footer (tags and favorite button) */}
        <Grid container className={styles.cardContainerflowter}>
          <Grid item>
            <Skeleton variant="rectangular" width={60} height={30} sx={{borderRadius: "1rem"}} />
          </Grid>
          <Grid item>
            <Skeleton variant="circular" width={24} height={24} />
          </Grid>
        </Grid>
      </Grid>

      <CardContent className={styles.cardContent}>
        {/* Skeleton for the card title */}
        <Typography component="h5" className={styles.cardHeading}>
          <Skeleton width="80%" height={28} />
        </Typography>

        {/* Skeleton for the company name */}
        <Skeleton width="60%" height={20} style={{ marginTop: 8 }} />

        {/* Skeleton for price or button */}
        <Grid>
          <Skeleton width="50%" height={40} style={{ marginTop: 16 }} />
        </Grid>

        {/* Skeleton for rating or button */}
        <ListItem className={styles.profileContainer} style={{ marginTop: 16 }}>
          <ListItemAvatar>
            <Skeleton variant="circular" width={40} height={40} />
          </ListItemAvatar>
          <ListItemText
            primary={<Skeleton width="80%" height={24} />}
            secondary={<Skeleton width="50%" height={20} />}
          />
        </ListItem>
      </CardContent>
    </Card>
  );
};

export default ArtistsCardSkeleton;
