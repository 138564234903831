/** @format */

import { Checkbox, Dialog, DialogTitle, Grid, Stack } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Customizedinput from "@components/Customizedinput";
import Customizeddate from "@components/Customizeddate";
import checkbox1 from "@images/checkbox.svg";
import Customizedbutton from "@components/Customizedbutton";
import {
  add_memberto_estimationlist,
  getEstimation_projectnames,
  getdropdowns_list,
} from "@services/Userservices";
import { customEnqueueSnackbar } from "@constants/Jsxconstants";
import { useSelector } from "react-redux";
import { api_dateformating } from "@helpers/Helperfunctions";
import { debounce } from "lodash";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CancelIcon from "@mui/icons-material/Cancel";
import { Portfolio_Styles } from "@styles/Dashboardstyles";
import CommonImage from "@components/CommonImage";
import { concatinate_displayname } from "../helpers/Helperfunctions";

const Addrequiredservice = (props) => {
  /**
   * This service type is used to differentiate the service type as per the api
   */
  const { open, close, sel_user, servicetype, Project_creation } = props;
  const JSX_styles = Portfolio_Styles();

  const user_data = useSelector((state) => state?.authentication?.data);
  const profile_data = useSelector(
    (state) => state.complete_profile.profile_data
  );
  const scrollref = useRef();
  const [allprojectypes, setAllprojecttypes] = useState([]);
  const [estimations_list, setEstimations_list] = useState([]);
  const [addEstimation, setAddEstimation] = useState({
    fromDate: "",
    toDate: "",
    projectIds: [],
    requiredQuantity: 1,
    rate: "",
    serviceType: "",
  });
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pagesize, setPagesize] = useState(20);
  const [projectscount, setProjectscount] = useState(0);
  const [project_details, setProject_details] = useState({
    projectname: "",
    fromDate: "",
    toDate: "",
    project_type: {},
  });

  /**
   *if flag is 1 adding to addestimation state
   *else adding to project_details state
   * @param {default} e
   * @param {integer} flag
   */
  const handleinputchange = (e, flag) => {
    const { name, value } = e.target;
    let inputval;
    if (name === "requiredQuantity") {
      // Handle empty values for "requiredQuantity"
      inputval = value === "" ? 0 : parseInt(value);
    } else {
      // Keep the original value for other names
      inputval = value;
    }
    if (flag === 1) {
      if (name === "requiredQuantity" && value === "0") return;
      setAddEstimation({ ...addEstimation, [name]: inputval });
    } else {
      setProject_details({ ...project_details, [name]: inputval });
    }
  };
  /**
   * Getting all project types on initial load
   */
  const getall_projecttypes = async () => {
    try {
      const projects_res = await getdropdowns_list({ type: 14 });
      if (projects_res?.status) {
        setAllprojecttypes(projects_res?.data);
      } else {
        customEnqueueSnackbar(projects_res?.message, { variant: "error" });
      }
    } catch (err) {
      console.log(err);
    }
  };
  /**
   * Get estimation names
   * @param {integer} pagenumber
   */
  const getEstimation_names = async (pagenumber) => {
    try {
      const { userId: otheruserid } = sel_user;
      let subcategoryid = sel_user?.subCategoryId;
      setLoading(true);
      let service_type =
        servicetype === 1
          ? 1
          : subcategoryid === 9
          ? 2
          : subcategoryid === 10
          ? 3
          : subcategoryid === 11
          ? 4
          : subcategoryid === 12
          ? 5
          : subcategoryid === 17
          ? 6
          : subcategoryid === 7
          ? 7
          : 1;
      let req = {
        userId: user_data?.id,
        min: (pagenumber ? pagenumber : pageNumber) * pagesize,
        max: pagesize,
        serviceUserId: otheruserid,
        serviceType: service_type,
        serviceId: 0,
      };
      if (servicetype === 2) {
        req.serviceId = sel_user?.id;
        req.serviceType = service_type;
      }
      const estimation_list_res = await getEstimation_projectnames(req);
      if (estimation_list_res?.status) {
        setProjectscount(estimation_list_res?.data?.count);
        setEstimations_list(estimation_list_res?.data?.result);
        if (estimation_list_res?.data?.count === 1) {
          estimation_list_res?.data?.result[0]?.existedInProject !== 1 &&
            handleselectproject(estimation_list_res?.data?.result[0]);
        }
        setLoading(false);
      } else {
        customEnqueueSnackbar(estimation_list_res?.message, {
          variant: "error",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleselectproject = (project) => {
    if (project?.existedInProject === 1) {
      customEnqueueSnackbar(
        "You have already added this profile to the project",
        {
          variant: "warning",
        }
      );
      return;
    }
    let id = project?.id;
    setAddEstimation((prevData) => ({
      ...prevData,
      projectIds:
        prevData.projectIds.indexOf(id) === -1
          ? [id] // Add the ID if not present ...prevData.projectIds,
          : prevData.projectIds.filter((projectId) => projectId !== id), // Remove the ID if present
    }));
  };
  /**
   * Getting the data of estimation list names in infinite scoll using debouce
   */
  const handlescroll = debounce(() => {
    const grid = scrollref.current;
    if (grid) {
      const { scrollTop, scrollHeight, clientHeight } = grid;
      const scrollTolerance = 10;
      const scrollDifference = scrollHeight - scrollTop - clientHeight;
      if (
        scrollDifference <= scrollTolerance &&
        estimations_list.length < projectscount &&
        !loading
      ) {
        setPageNumber((prev) => prev + 1);
        getEstimation_names(pageNumber + 1);
      }
    }
  }, 600);

  /**Adding member to selected estimation list */
  const handleadd_memberto_estimationlist = async () => {
    try {
      if (addEstimation?.fromDate === "") {
        customEnqueueSnackbar("Please select the from date.", {
          variant: "warning",
        });
        return;
      } else if (addEstimation?.toDate === "") {
        customEnqueueSnackbar("Please select the to date.", {
          variant: "warning",
        });
        return;
      } else if (addEstimation?.projectIds.length === 0) {
        customEnqueueSnackbar("Please select the project.", {
          variant: "warning",
        });
        return;
      } else if (
        new Date(addEstimation?.fromDate) > new Date(addEstimation?.toDate)
      ) {
        customEnqueueSnackbar("To date should be greater than from date.", {
          variant: "warning",
        });
        return;
      } else {
        setLoading(true);
        let rate_val;
        const { userId: otheruserid } = sel_user;
        let subcategoryid = sel_user?.subCategoryId;
        rate_val =
          servicetype === 1 && props?.selected_package !== undefined
            ? Object.keys(props.selected_package).length > 0
              ? parseFloat(props?.selected_package?.planRate) /
                props?.selected_package?.noOfDays
              : 0
            : subcategoryid === 7
            ? sel_user?.rentPerDay
            : [9, 10, 11, 12, 17].includes(subcategoryid)
            ? sel_user?.price
            : "0";
        let service_type =
          servicetype === 1
            ? 1
            : subcategoryid === 9
            ? 2
            : subcategoryid === 10
            ? 3
            : subcategoryid === 11
            ? 4
            : subcategoryid === 12
            ? 5
            : subcategoryid === 17
            ? 6
            : subcategoryid === 7 && 7;
        const req = {
          userId: user_data?.id,
          fromDate: api_dateformating(addEstimation?.fromDate),
          toDate: api_dateformating(addEstimation?.toDate),
          serviceUserId: otheruserid,
          projectIds: addEstimation.projectIds,
          requiredQuantity: addEstimation?.requiredQuantity,
          rate: rate_val.toString(),
          serviceType: service_type,
          serviceId: sel_user?.id, //service_type ? null :
          // currency:profile_data?.userDetails?.country?.currency
        };

        const added_res = await add_memberto_estimationlist(req);
        if (added_res?.status) {
          customEnqueueSnackbar(added_res?.message, { variant: "success" });
          setAddEstimation({
            fromDate: "",
            toDate: "",
            projectIds: [],
            requiredQuantity: 1,
            rate: "",
            serviceType: "",
          });
          close(1);
        } else {
          customEnqueueSnackbar(added_res?.message, { variant: "error" });
        }
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (open && Object.keys(sel_user).length > 0) {
      getall_projecttypes();
      getEstimation_names();
    }
  }, [props]);
  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        PaperProps={{
          sx: {
            // position: "fixed",
            // top: "10%",
            // m: 0,
            width: 450,
            maxWidth: 600,
            borderRadius: "1rem",
            "::-webkit-scrollbar": { display: "none" },
            "@media screen and (max-width: 599px)": {
              width: 290,
              maxWidth: 290,
            },
          },
        }}
      >
        {/* <DialogTitle
          textAlign={"right"}
          sx={{ padding: "10px", paddingBottom: "0px", height: "1.4rem" }}
        > */}
        {/* <HighlightOffIcon
            sx={{ color: "rgba(0, 0, 0, 0.6)" }}
            onClick={close}
          /> */}
        <CancelIcon className={JSX_styles.closeiconstyle} onClick={close} />
        {/* </DialogTitle> */}
        <Grid container direction={"column"} p={3} gap={2} mt={1}>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            textAlign={"start"}
            fontWeight={700}
          >
            Duration of the Service
             {/* Required */}
          </Grid>
          <Grid container direction={"column"} gap={1}>
            <Grid item container gap={1}>
              <Grid item xs={"auto"} fontWeight={600}>Name:</Grid>
              <Grid item xs>
                {concatinate_displayname(
                  sel_user?.userDetails?.firstName,
                  sel_user?.userDetails?.lastName
                )}
              </Grid>
            </Grid>
            <Grid item container gap={1}>
              <Grid item xs={"auto"} fontWeight={600}>Sub category:</Grid>
              <Grid item xs>{sel_user?.subcategory?.subcategoryName}</Grid>
            </Grid>
          </Grid>
          <Grid item container mt={1} gap={4}>
            <Grid item md={5.5} sm={5.5}>
              <Stack spacing={2}>
                <Grid item>From </Grid>
                <Grid item>
                  <Customizeddate
                    value={addEstimation?.fromDate}
                    placeholder={"Select From"}
                    minDate={
                      new Date()
                      // addEstimation?.toDate === ""
                      //   ? new Date()
                      //   : addEstimation?.toDate
                    }
                    onChange={(newValue) => {
                      if (newValue === null || newValue === "") {
                        setAddEstimation((prev) => ({
                          ...prev,
                          fromDate: "",
                          toDate: "",
                        }));
                      } else {
                        setAddEstimation((prev) => ({
                          ...prev,
                          fromDate: newValue,
                          toDate: "",
                        }));
                      }
                    }}
                  />
                </Grid>
              </Stack>
            </Grid>
            <Grid item md={5.5} sm={5.5}>
              <Stack spacing={2}>
                <Grid item>TO </Grid>{" "}
                <Grid item>
                  <Customizeddate
                    value={addEstimation?.toDate}
                    placeholder={"Select To"}
                    minDate={
                      addEstimation?.fromDate === ""
                        ? new Date()
                        : addEstimation?.fromDate
                    }
                    onChange={(newValue) => {
                      if (newValue === null || newValue === "") {
                        setAddEstimation((prev) => ({
                          ...prev,
                          toDate: "",
                        }));
                      } else {
                        setAddEstimation((prev) => ({
                          ...prev,
                          toDate: newValue,
                        }));
                      }
                    }}
                  />{" "}
                </Grid>
              </Stack>
            </Grid>
          </Grid>
          {servicetype === 2 &&
          [7, 10, 11, 12].includes(sel_user?.subCategoryId) ? (
            <Grid item>
              <Stack spacing={2}>
                <Grid item>
                  {sel_user?.subCategoryId === 10
                    ? "Quantity per day"
                    : "Quantity"}
                </Grid>
                <Grid item>
                  <Customizedinput
                    value={addEstimation?.requiredQuantity}
                    name="requiredQuantity"
                    onChange={(e) => handleinputchange(e, 1)}
                    fullWidth
                  />
                </Grid>
              </Stack>
            </Grid>
          ) : null}
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Grid item fontWeight={700}>
              Add to Existing Lists?
            </Grid>
            <Grid
              item
              fontWeight={700}
              sx={{ fontSize: "16px", color: "#04BEF8", cursor: "pointer" }}
              onClick={() => Project_creation(1)}
            >
              + New Project
            </Grid>
          </Stack>
          {estimations_list.length > 0 && (
            <Grid
              item
              ref={scrollref}
              onScroll={handlescroll}
              maxHeight={"20vh"}
              sx={{
                overflow: "hidden",
                overflowY: "scroll",
                "::-webkit-scrollbar": { display: "none" },
              }}
            >
              {estimations_list.map((item, index) => {
                return (
                  <>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Checkbox
                        sx={{ "&.Mui-checked": { color: "#04BEF8" } }}
                        disableRipple
                        icon={
                          <CommonImage src={checkbox1} alt="not checked " />
                        }
                        onClick={() => handleselectproject(item)}
                        checked={
                          addEstimation?.projectIds.includes(item?.id) &&
                          item?.existedInProject !== 1
                            ? true
                            : false
                        }
                      />
                      <span>{item?.projectName}</span>
                    </Stack>
                  </>
                );
              })}
            </Grid>
          )}
          <Grid item container gap={2} justifyContent={"center"}>
            <Grid item md={"auto"} sm={"auto"} xs={"auto"}>
              <Customizedbutton
                data="Cancel"
                // swidth={"170px"}
                // sheight={"46px"}
                size={"xl"}
                // styles={{
                //   fontSize: "18px",
                //   fontWeight: "600",
                //   borderRadius: "2rem",
                //   "@media screen and (max-width: 599px)": {
                //     width: "100px",
                //     height: "40px",
                //     fontSize: "14px",
                //   },
                // }}
                bgcolor={"#333333"}
                onClick={close}
              />
            </Grid>
            <Grid item md={"auto"} sm={"auto"} xs={"auto"}>
              <Customizedbutton
                data="Add"
                // swidth={"170px"}
                // sheight={"46px"}
                // styles={{
                //   fontSize: "18px",
                //   fontWeight: "600",
                //   borderRadius: "2rem",
                //   "@media screen and (max-width: 599px)": {
                //     width: "100px",
                //     height: "40px",
                //     fontSize: "14px",
                //   },
                // }}
                size={"xl"}
                onClick={() => handleadd_memberto_estimationlist()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default Addrequiredservice;
