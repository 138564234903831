import { Dialog, Grid, IconButton } from "@mui/material";
import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Customizedbutton from "@components/Customizedbutton";

import CancelIcon from "@mui/icons-material/Cancel";
import { Portfolio_Styles } from "@styles/Dashboardstyles";
const Contactadmin = (props) => {
  const { open, close } = props;
  const JSX_styles = Portfolio_Styles();

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        sx: {
          position: "fixed",
          top: "30%",
          borderRadius: "2rem",
          m: 0,
          minHeight: 150,
          maxHeight: 500,
          width: 500,
          maxWidth: 500,
          padding: "1rem",
          display: "flex",
          alignItems: "center",
          backdropFilter: "blur(15px)",
          "@media screen and (max-width: 599px)": {
            width: 260,
            maxWidth: 260,
          },
        },
      }}
    >
      <Grid container justifyContent={"flex-end"}>
        <IconButton
          sx={{ "&:hover": { background: "none" }, color: "none", }}
          onClick={close}
        >
          {/* <HighlightOffIcon sx={{ color: "rgba(0, 0, 0, 0.6)" }} /> */}
          <CancelIcon 
          sx={{
            position: "absolute",
            top: 0,
            right: -0,
            color: "#04BEF8",
          }}
          // className={JSX_styles.closeiconstyle} 
          onClick={close} />
        </IconButton>{" "}
      </Grid>
      <Grid container direction="column" spacing={3} alignItems="center">
        <Grid
          item
          textAlign={"center"}
          fontWeight={"500"}
          mt={1}
          sx={{ fontSize: { md: "22px", sm: "22px" } }}
        >
          Your account has been restricted by the administrator
        </Grid>
        <Grid item container justifyContent={"center"}>
          <Customizedbutton
            data={"Contact Admin"}
            swidth="170px"
            sheight="46px"
            styles={{ borderRadius: "2rem", marginBottom: "1.5rem" }}
            bgcolor="#04BEF8"
            onClick={() => {
              window.location.href = "mailto:talktomoca@gmail.com";
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Contactadmin;
