import { Grid, Stack } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Mocaheader from '@parts/Mocaheader'
import Mocafooter from '@parts/Mocafooter'

const Userlayout = () => {
  return (
    <>
      <Stack direction={"column"} style={{height:'100vh'}}>
        <Grid item style={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1001, }}>
          <Mocaheader/>
        </Grid>
        <Grid item style={{marginTop:'calc( 0rem + 88px )'}}>
            <Outlet/>
        </Grid>
        <Grid item >
            <Mocafooter/>
        </Grid>
      </Stack>
    </>
  );
};

export default Userlayout;
