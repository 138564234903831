import { Button } from "@mui/material";
import React from "react";
import { style_exports } from "@exports/style_exports";
const Customizedbutton = ({
  data,
  bgcolor,
  swidth,
  sheight,
  styles,
  textColor,
  size,
  onClick,
  elipses={},
  ...otherprops
}) => {
  let button_sizes = {
    nl:'0px',
    sm:'0.4rem 1rem',
    md:'0.5rem 1.5rem',
    lg:'0.5rem 2rem',
    xl:'0.5rem 2.5rem',
    xxl:'0.5rem 3rem',
  }
 
  return (
    <Button
      disableRipple
      {...otherprops}
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: `${
          bgcolor !== undefined ? bgcolor : style_exports.btn_bgcolor
        }!important`,
        size: "auto",
        color: `${
          textColor ? textColor : style_exports.btn_txtcolor
        }!important`,
        borderRadius: "1.5rem",
        width: swidth,
        height: sheight,
        textTransform: "none",
        cursor: "pointer",
        "&:hover": {
          backgroundColor:
            bgcolor !== undefined ? bgcolor : style_exports.btn_bgcolor,
        },
        padding:button_sizes[size??'md'],
        ...styles,
      }}
      onClick={onClick}
    >
      <span  style={{...elipses}}>{data}</span>
     
    </Button>
  );
};

export default Customizedbutton;
