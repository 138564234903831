import Userapiservice from "@services/Userapiservice";
import { Envexports } from "@exports/Envexports";
import axios from "axios";
import { StorageClient } from "@helpers/Helperfunctions";
import Cookies from "js-cookie";
const getall_countries = async (payload) => {
  const res = await Userapiservice(
    "getAllCountryCode",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const getall_states = async (payload) => {
  const res = await Userapiservice(
    "getStates",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const create_edit_castingcall = async (payload) => {
  const res = await Userapiservice(
    "addOrEditCastingCalls",
    payload,
    "POST",
    "multipart/form-data"
  );
  return res;
};
const getcastingcallbyid = async (payload) => {
  const res = await Userapiservice(
    "getCastingCallById",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const getcastingcall_dropdown = async (payload) => {
  const res = await Userapiservice(
    "getDropdownListCastingCall",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const edit_lookingfor = async (payload) => {
  const res = await Userapiservice(
    "editCastingRequirements",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const delete_lookingforcard = async (payload) => {
  const res = await Userapiservice(
    "deleteCastingRequirements",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const delete_castingcall = async (payload) => {
  const res = await Userapiservice(
    "deleteCastingCall",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const dropdown_list = async (payload) => {
  const res = await Userapiservice(
    "getDropdownList",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
/**Refresh token api call */
const refreshtokenApi = async (payload) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: payload?.refreshToken,
  };
  return axios
    .post(`${Envexports.baseurl}refreshAccessToken`, {userId:payload?.userId}, { headers })
    .then((response) => {
      // Handle the response
      const data = response?.data;
      if (data?.status) {
        // Update the access token and its expiration in StorageClient
        StorageClient.setItem("token", data.data.accessToken);
        return data.data.accessToken;
      }
    })
    .catch((error) => {
      // Handle errors
      if (error?.response?.status === 401) {
        StorageClient.clear();
        localStorage.removeItem("persist:root"); // Replace 'root' with your key
        window.location.replace("/");
        Cookies.remove("refreshtoken");
        Cookies.remove("userid");
        return;
      }
      console.error("Error refreshing access token:", error);
    });
};
const get_equiments_dropdown = async (payload) => {
  const res = await Userapiservice(
    "getEquipmentList",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const getequipment_models= async(payload)=>{
  const res = await Userapiservice("getEquipmentModels",payload,"POST","application/json");
  return res;
}
const get_brands_dropdown = async (payload) => {
  const res = await Userapiservice(
    "getEquipmentBrands",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const get_how_it_works = async (payload) => {
  const res = await Userapiservice(
    "getVideo",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const upload_how_it_works = async (payload) => {
  const res = await Userapiservice(
    "addorEditVideoUpload",
    payload,
    "POST",
    "multipart/form-data"
  );
  return res;
};
const currency_conversion = async (payload) => {
  const res = await Userapiservice("convertCurrency",payload,"POST","application/json");
  return res;
}
const getHomeBanners = async (payload) => {
  const res = await Userapiservice("getAdminHomeBanner",payload,"POST","application/json");
  return res;
}
const getTestimonials = async (payload) => {
  const res = await Userapiservice("getClientTestimonial",payload,"POST","application/json");
  return res;
}
const getHowItWorksContent = async (payload) => {
  const res = await Userapiservice("getHowitWorks",payload,"POST","application/json");
  return res;
}
const getAboutUsApi = async (payload) => {
  const res = await Userapiservice("getAboutUs",payload,"POST","application/json");
  return res;
}
const getcurrentutctime = async()=>{
  const res = await fetch('https://worldtimeapi.org/api/timezone/Etc/UTC');
  return res.json();
}
const getawsputurls = async(payload)=>{
  const res  = await Userapiservice("getPutUrls", payload, "POST", "application/json");
  return res;
}
const uploadfiles_aws = async({filesto_upload,puturlsdata})=>{
  const uploadPromises = [];
        // Prepare upload promises for files and thumbnails
        Object.keys(filesto_upload).forEach((urlObj) => {
          const mediaUploadUrl = puturlsdata[urlObj]?.mediaUrl;
          const thumbnailUploadUrl = puturlsdata[urlObj]?.thumbnailUrl;
          const file = filesto_upload[urlObj]?.media;
          const thumbnailFile = filesto_upload[urlObj]?.thumbnailmedia;
  
          if (mediaUploadUrl && file) {
            uploadPromises.push(
              axios.put(mediaUploadUrl, file, {
                headers: { "Content-Type": file.type },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  console.log(percentCompleted, "percent completed");
                },
              }).then(response => ({ status: 'fulfilled', response }))
                .catch(error => ({ status: 'rejected', error }))
            );
          }
  
          if (thumbnailUploadUrl && thumbnailFile) {
            uploadPromises.push(
              axios.put(thumbnailUploadUrl, thumbnailFile, {
                headers: { "Content-Type": "image/jpeg" },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  console.log(percentCompleted, "thumbnail percent completed");
                },
              }).then(response => ({ status: 'fulfilled', response }))
                .catch(error => ({ status: 'rejected', error }))
            );
          }
        });
  return axios.all(uploadPromises);
}
export {
  getall_countries,
  getall_states,
  create_edit_castingcall,
  getcastingcall_dropdown,
  dropdown_list,
  getcastingcallbyid,
  edit_lookingfor,
  delete_lookingforcard,
  delete_castingcall,
  refreshtokenApi,
  get_equiments_dropdown,
  get_brands_dropdown,
  get_how_it_works,
  upload_how_it_works,
  currency_conversion,
  getHomeBanners,
  getTestimonials,
  getHowItWorksContent,
  getAboutUsApi,
  getcurrentutctime,
  getawsputurls,
  uploadfiles_aws,
  getequipment_models
};
