import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    converted_currencies:{}
  };

const Currencyslice = createSlice({
    name:"currencies",
    initialState,
    reducers:{
        setcurrencies(state,action){
            console.log(action.payload,"apayload")
            state.converted_currencies={...state.converted_currencies,...action.payload};
        }
    }
})
export const CurrencyActions = Currencyslice.actions;
export default Currencyslice;