import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import Loginslice from "@store/Loginslice";
import Completeprofileslice from "@store/Completeprofileslice";
import Allcategoriesslice from "@store/Allcategoriesslice";
import Portfoliocrudslice from "@store/Portfoliocrudslice";
import Admincastingcallslice from "@adminstore/Admincastingcallslice";
import Subadminslice from "@adminstore/Subadminslice";
import Adminsubscriptionslice from "@adminstore/Adminsubscriptionslice";
import { decryptData, encryptData } from "@helpers/Encryptionutils";
import Estimationslice from "@store/Estimationslice";
import Currencyslice from "@store/Currencyslice";
import AutoSaveSlice from "@store/AutoSaveSlice";
// Custom storage engine with logging
const loggingStorage = {
  getItem: async (key) => {
    const encryptedData = await storage.getItem(key);
    if (!encryptedData) return null; // Handle case where no data is stored
    // const data = await decryptData(storage.getItem(key));
    // return data;
    const decryptedData = await decryptData(encryptedData);
    return decryptedData;
  },
  setItem: async (key, value) => {
    await storage.setItem(key, encryptData(value));
  },
  removeItem: async (key) => {
    await storage.removeItem(key);
  },
};
const persistConfig = {
  key: "root",
  version: 1,
  storage: loggingStorage,
};
const reducer = combineReducers({
  authentication: Loginslice.reducer,
  complete_profile: Completeprofileslice.reducer,
  all_categories: Allcategoriesslice.reducer,
  portfoliocrud: Portfoliocrudslice.reducer,
  admincastingcall: Admincastingcallslice.reducer,
  subadmin: Subadminslice.reducer,
  adminplans: Adminsubscriptionslice.reducer,
  estimationlist: Estimationslice.reducer,
  currencies: Currencyslice.reducer,
  autosave: AutoSaveSlice.reducer,
});
const persistedreducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedreducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;
