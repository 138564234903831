import { customEnqueueSnackbar } from "@constants/Jsxconstants";

const chat_display_name = (userdata, chatdetails) => {
  const { chatType, memberList } = chatdetails;
  /**one - one chat */
  if (chatType === 1) {
    let user = memberList.find((item) => item?.userId !== userdata?.id);
    return user?.name;
  } else {
    return "prudhvi";
  }
};

const chat_display_role = (userdata, chatdetails) => {
  const { chatType, memberList } = chatdetails;
  /**one - one chat */
  if (chatType === 1) {
    let user = memberList.find((item) => item?.userId !== userdata?.id);
    return user?.role;
  } else {
    return "prudhvi";
  }
};

const get_recentmessage_time = (timestamp) => {
  if (timestamp === undefined) {
    return null;
  }
  let milliseconds = timestamp * 1000;
  const date = new Date(milliseconds);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
  const formattedTime = `${formattedHours}:${minutes} ${ampm}`;
  return formattedTime;
};

const getting_sender_receiverids = (userdata, selected) => {
  const { userId: senderid } = userdata;
  const { memberIds: ids = [] } = selected;
  const receiverid = ids.find((item) => item !== senderid);
  return { senderid, receiverid };
};

const get_unseenmessages_count = (chatroomobj, userdata) => {
  const { count } = chatroomobj;
  const { id } = userdata;
  // Exclude the value associated with 'id1'
  const { [id]: excludedvalue, ...rest } = count[0];
  // Calculate the sum of the remaining values
  const totalCount = Object.values(rest).reduce((sum, value) => sum + value, 0);
  return totalCount;
};

const update_unseenmesssages_count = (chatroomobj, userdata, usertype) => {
  const { count } = chatroomobj;
  const { userId: id } = userdata;
  const countobj = count;
  // Ensure the property to exclude exists in the data
  if (countobj.hasOwnProperty(id)) {
    // Create a new object with counts updated
    let updatedData;
    if (usertype === 1) {
      updatedData = Object.keys(countobj).reduce((result, key) => {
        result[key] = key === id ? 0 : countobj[key] + 1;
        return result;
      }, {});
    } else {
      updatedData = { ...countobj };
      updatedData[id] = 0;
    }
    return updatedData;
  } else {
    customEnqueueSnackbar("Messages not found.", { variant: "warning" });
  }
};
/**
 * Getting total messages and inbox count
 * @param {object} badgeobj
 */
const total_messages_inbox_count = (badgeobj) => {
  const { totalmessages, inboxcount } = Object.values(badgeobj).reduce(
    (accumulator, currentValue) => {
      accumulator.totalmessages += currentValue;
      accumulator.inboxcount += currentValue > 0 ? 1 : 0;
      return accumulator;
    },
    { totalmessages: 0, inboxcount: 0 }
  );
  return { totalmessages, inboxcount };
};
/**
 * if type==1 then we will get the messages count displayed in the sidebar
 * type==2 the we will the member count who receive the unviewed mesages
 * @param {array} users
 * @param {integer} type
 */
const get_inbox_messages_count = (users, userdata, type) => {
  const { id: userid } = userdata;
  let totalCount;
  if (type === 1) {
    totalCount = users.reduce((total, obj) => {
      if (obj.count && obj.count[userid] > 0) {
        // Add the count to the total
        total += 1;
      }
      return total;
    }, 0);
  } else {
    totalCount = users.reduce((total, obj) => {
      if (obj.count && obj.count[userid]) {
        // Add the count to the total
        total += obj.count[userid];
      }
      return total;
    }, 0);
  }

  return totalCount;
};
const get_chat_date = (timestampInSeconds) => {
  const date = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds

  // Define month names
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get month and day
  const month = months[date.getMonth()];
  const day = date.getDate();
  // Format the date as "Month Day"
  if(month === undefined || isNaN(day)) return null;
  const formattedDate = `${month} ${day}`;
  // Output: "July 29";
  return formattedDate;
};
const chat_date_change = (prev, current, next) => {
  let prev_msg;
  let prev_msg_date;
  const current_msg = new Date(current * 1000); //converting date in milliseconds
  const current_msg_date = current_msg.getDate();
  if (prev !== undefined) {
    prev_msg = new Date(prev * 1000);
    prev_msg_date = prev_msg.getDate();
  } else {
    return true;
  }
  if (next === undefined) {
    if (current_msg_date === prev_msg_date) {
      return false;
    } else {
      return true;
    }
  }

  const next_msg = new Date(next * 1000);
  const next_msg_date = next_msg.getDate();
  if (current_msg_date < next_msg_date || current_msg_date > prev_msg_date) {
    return true;
  } else {
    return false;
  }
};

const add_remove_deletedusers = async (recentmessage, userids) => {
  let updated_recentmessage;
  let { deletedUsers, nonDeletedUsers } = recentmessage;
  // If deletedUsers array does not exist, create it and add your given id
  if (!deletedUsers) {
    deletedUsers = [...userids];
  }
  else {
    deletedUsers.push(
      ...new Set(userids.filter((user_id) => !deletedUsers.includes(user_id)))
    );
  }

  // If nonDeletedUsers array does not exist, create it and remove your given id
  if (!nonDeletedUsers) {
    nonDeletedUsers = [];
  }
  else {
    nonDeletedUsers = nonDeletedUsers.filter(
      (user_id) => !userids.includes(user_id)
    );
  }
  updated_recentmessage = {
    ...recentmessage,
    deletedUsers,
    nonDeletedUsers,
  };
  return { deletedUsers, nonDeletedUsers };
};
export {
  chat_display_name,
  get_recentmessage_time,
  chat_display_role,
  getting_sender_receiverids,
  get_unseenmessages_count,
  update_unseenmesssages_count,
  get_inbox_messages_count,
  get_chat_date,
  chat_date_change,
  total_messages_inbox_count,
  add_remove_deletedusers,
};
