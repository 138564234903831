import {
  Skeleton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { castingcallCardStyles } from "@styles/CardStyles";

export const CastingcallCardSkeleton = () => {
  const styles = castingcallCardStyles();

  return (
    <Card className={styles.cardContainer}>
      <CardHeader
        className={styles.cardHeader}
        action={<Skeleton variant="circular" width={25} height={25} />}
      />
      <CardContent className={styles.cardBody}>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
          gap={1}
        >
          <Skeleton variant="circular" width={50} height={50} />
          <Skeleton variant="text" width={120} height={30} />
        </Grid>
        <Grid container gap={1.5}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Skeleton variant="text" width={100} height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width={100} height={20} />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Skeleton variant="text" width={100} height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width={100} height={20} />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Skeleton variant="text" width={100} height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width={100} height={20} />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Skeleton variant="text" width={120} height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width={100} height={20} />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={styles.cardFooter}>
        <ListItem className={styles.profileContainer}>
          <ListItemAvatar>
            <Skeleton variant="circular" width={45} height={45} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Grid>
                <Skeleton variant="text" width={80} height={20} />
                <Skeleton variant="text" width={80} height={15} />
              </Grid>
            }
            secondary={<Skeleton variant="text" width={100} height={15} />}
          />
        </ListItem>
      </CardActions>
    </Card>
  );
};
