import { Chip, Divider, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Usersidebarstyles } from "@styles/Usersidebarstyles";
import { useDispatch, useSelector } from "react-redux";
import { LoginActions } from "@store/Loginslice";
import { StorageClient } from "@helpers/Helperfunctions";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../Firebase/Firebaseconfig";
import {
  get_inbox_messages_count,
  total_messages_inbox_count,
} from "@helpers/Firebasehelpers";
import { Message_Styles } from "@styles/Dashboardstyles";
import CustomizedAlert from "../../models/CustomizedAlert";
import { completeprofile_Actions } from "../../store/Completeprofileslice";
import { userLogout } from "../../services/Userservices";
import { customEnqueueSnackbar } from "../constants/Jsxconstants";

const Usersidebar = ({ close }) => {
  const sidebarstyles = Usersidebarstyles();
  // const JSX_styles = Message_Styles();
  const dispatch = useDispatch();
  const users_collection = collection(db, "users_info");
  const user_data = useSelector((state) => state.authentication?.data);
  const profile_data = useSelector(
    (state) => state.complete_profile.profile_data
  );
  const [listChat, setListChat] = useState([]);
  const [sender_info, setSender_info] = useState({});
  const Logout = async (notConfirmDeactive) => {
    if (notConfirmDeactive) {
      setAlertOpen({
        message: (
          <Grid textAlign={"center"}>Are you sure you want to Logout?</Grid>
        ),
        open: true,
      });
      return;
    } else {
      const res = await userLogout(user_data?.id);
      if (res?.status) {
        dispatch(LoginActions.resetlogin());
        dispatch(completeprofile_Actions.reset_complete_profiles());
        StorageClient.clear();
        localStorage.removeItem("persist:root"); // Replace 'root' with your key
        customEnqueueSnackbar(res?.message, { variant: "success" });
      } else {
        customEnqueueSnackbar(res?.message, { variant: "error" });
      }
    }
  };
  const [alertOpen, setAlertOpen] = useState({
    open: false,
    message: null,
  });
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (close) {
      close();
    }
  };
  // const getchatusers_list = async () => {
  //   try {
  //     const getusersquery = query(
  //       chatroom_users,
  //       where("memberIds", "array-contains", user_data?.id),
  //       where("isArchevied", "==", false)
  //     );
  //     onSnapshot(getusersquery, (snapshot) => {
  //       let chats = [];
  //       snapshot.forEach((doc) => {
  //         chats.push({ ...doc.data(), id: doc.id });
  //       });
  //       setListChat(chats);
  //     });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const get_sender_info = () => {
    try {
      const user_query = doc(users_collection, user_data?.id);
      onSnapshot(user_query, (docsnapshot) => {
        if (docsnapshot.exists()) {
          const sender_data = docsnapshot.data();
          setSender_info(sender_data);
        } else {
          // console.log("Document doesn't exists");
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    get_sender_info();
  }, []);
  return (
    <aside style={{ marginTop: "0.5rem", position: "relative" }}>
      {/* <Grid sx={{ position: "sticky", top: 0 }}> */}
        <span style={{ fontSize: "30px", fontWeight: 700, lineHeight: "49px" }}>
          My Account
        </span>
        <Divider sx={{ marginTop: "0.5rem" }} />
      {/* </Grid> */}
      <Grid
        container
        direction="column"
        spacing={5}
        mt={1}
        style={{ marginBottom: "1rem" }}
      >
        <Grid item>
          <NavLink
            end
            to="/dashboard"
            className={({ isActive }) =>
              isActive ? sidebarstyles.active : sidebarstyles.inactive
            }
            onClick={scrollTop}
          >
            Dashboard
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            end
            to={
              profile_data?.userDetails?.profileStatus
                ? "/dashboard/basicinfo"
                : "/mocalogin/profilecreation"
            }
            className={({ isActive }) =>
              isActive ? sidebarstyles.active : sidebarstyles.inactive
            }
            onClick={scrollTop}
          >
            Basic Info
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            to={
              profile_data?.userDetails?.profileStatus
                ? "/dashboard/myportfolio"
                : "/mocalogin/profilecreation"
            }
            className={({ isActive }) =>
              isActive ? sidebarstyles.active : sidebarstyles.inactive
            }
            onClick={scrollTop}
          >
            My Portfolio
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            to={
              profile_data?.userDetails?.profileStatus
                ? "/dashboard/pricingplans"
                : "/mocalogin/profilecreation"
            }
            className={({ isActive }) =>
              isActive ? sidebarstyles.active : sidebarstyles.inactive
            }
            onClick={scrollTop}
          >
            My Pricing Plans
          </NavLink>
        </Grid>
        <Grid item style={{ paddingRight: "5rem", width: "280px" }}>
          <Divider />
        </Grid>
        <Grid item>
          <NavLink
            to={
              profile_data?.userDetails?.profileStatus
                ? "/dashboard/messages"
                : "/mocalogin/profilecreation"
            }
            className={({ isActive }) =>
              isActive ? sidebarstyles.active : sidebarstyles.inactive
            }
            onClick={scrollTop}
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "185px",
            }}
          >
            Messages/Support
            <Stack direction="row" spacing={1} mt={0.5} pl={1}>
              {sender_info?.badge !== undefined &&
                Object.keys(sender_info.badge).length > 0 &&
                total_messages_inbox_count(sender_info?.badge)?.totalmessages >
                  0 && (
                  <Grid item>
                    <Chip
                      label={
                        total_messages_inbox_count(sender_info?.badge)
                          ?.totalmessages
                      }
                      size="small"
                      className={sidebarstyles.chipStyles}
                    />
                  </Grid>
                )}
            </Stack>
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            to={
              profile_data?.userDetails?.profileStatus
                ? "/dashboard/notifications"
                : "/mocalogin/profilecreation"
            }
            className={({ isActive }) =>
              isActive ? sidebarstyles.active : sidebarstyles.inactive
            }
            onClick={scrollTop}
          >
            Notifications
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            to={
              profile_data?.userDetails?.profileStatus
                ? "/dashboard/applicationstatus"
                : "/mocalogin/profilecreation"
            }
            className={({ isActive }) =>
              isActive ? sidebarstyles.active : sidebarstyles.inactive
            }
            onClick={scrollTop}
          >
            Application Status
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            to={
              profile_data?.userDetails?.profileStatus
                ? "/dashboard/myrequests"
                : "/mocalogin/profilecreation"
            }
            className={({ isActive }) =>
              isActive ? sidebarstyles.active : sidebarstyles.inactive
            }
            onClick={scrollTop}
          >
            My Requests
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            to={
              profile_data?.userDetails?.profileStatus
                ? "/dashboard/favourites"
                : "/mocalogin/profilecreation"
            }
            className={({ isActive }) =>
              isActive ? sidebarstyles.active : sidebarstyles.inactive
            }
            onClick={scrollTop}
          >
            My Favourites
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            to={
              profile_data?.userDetails?.profileStatus
                ? "/dashboard/estimations"
                : "/mocalogin/profilecreation"
            }
            className={({ isActive }) =>
              isActive ? sidebarstyles.active : sidebarstyles.inactive
            }
            onClick={scrollTop}
          >
            My Estimation List
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            to={
              profile_data?.userDetails?.profileStatus
                ? "/dashboard/completedprojects"
                : "/mocalogin/profilecreation"
            }
            className={({ isActive }) =>
              isActive ? sidebarstyles.active : sidebarstyles.inactive
            }
            onClick={scrollTop}
          >
            My Projects{" "}
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            to={
              profile_data?.userDetails?.profileStatus
                ? "/dashboard/reviews"
                : "/mocalogin/profilecreation"
            }
            className={({ isActive }) =>
              isActive ? sidebarstyles.active : sidebarstyles.inactive
            }
            onClick={scrollTop}
          >
            Reviews
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            to={
              profile_data?.userDetails?.profileStatus
                ? "/dashboard/ratings"
                : "/mocalogin/profilecreation"
            }
            className={({ isActive }) =>
              isActive ? sidebarstyles.active : sidebarstyles.inactive
            }
            onClick={scrollTop}
          >
            Ratings Given
          </NavLink>
        </Grid>
        <Grid item sx={{ paddingRight: "5rem", width: "280px" }}>
          <Divider />
        </Grid>
        <Grid item>
          <NavLink
            to={
              profile_data?.userDetails?.profileStatus
                ? "/dashboard/accountsettings"
                : "/mocalogin/profilecreation"
            }
            className={({ isActive }) =>
              isActive ? sidebarstyles.active : sidebarstyles.inactive
            }
            onClick={scrollTop}
          >
            Account Settings
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            to="/"
            onClick={(event) => {
              event.preventDefault();
              Logout(true);
            }}
            className={({ isActive }) =>
              isActive ? sidebarstyles.active : sidebarstyles.inactive
            }
          >
            Logout
          </NavLink>
        </Grid>
      </Grid>
      {alertOpen && (
        <CustomizedAlert
          open={alertOpen?.open}
          close={() =>
            setAlertOpen({
              open: false,
              message: null,
            })
          }
          action={() => Logout(false)}
          textOrHtml={alertOpen?.message}
        />
      )}
    </aside>
  );
};

export default Usersidebar;
