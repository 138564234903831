import React from "react";

const VideoPlayButton = (props) => {
  return (
    <>
      <svg
        width="50"
        height="50"
        viewBox="0 0 85 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect
          x="0.0585938"
          y="0.766113"
          width="84.9375"
          height="84.9375"
          rx="42.4688"
          fill="black"
          fill-opacity="0.5"
        />
        <path
          d="M63.4625 41.2706C65.7706 42.6032 65.7706 45.9346 63.4625 47.2672L35.1385 63.6201C32.8304 64.9526 29.9453 63.2869 29.9453 60.6218L29.9453 27.916C29.9453 25.2509 32.8304 23.5852 35.1385 24.9177L63.4625 41.2706Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default VideoPlayButton;
