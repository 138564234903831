import { makeStyles } from "@mui/styles";
import { style_exports } from "@exports/style_exports";
import { styled } from "@mui/material";
import Switch from "@mui/material/Switch";
export const Request_styles = makeStyles({
  paddingwidthstyle:{
    padding: "0rem 1rem",
    backgroundColor:style_exports.premium_bg_clr,
    borderRadius: "1rem",
    width: "100%",
  },
  textoverstyle:{
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: 2,
    fontWeight: 700,
    height: "55px",
    fontSize: "20px",
    maxWidth: "100% !important",
    wordWrap: "break-word",
  },
  requests_tab: {
    "& .MuiTab-root.Mui-selected": {
      color: "White",
      backgroundColor: `${style_exports.skyblue_color}!important`,
      borderRadius: "0.5rem 0.5rem 0rem 0rem",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTab-root": {
      padding: "0px",
      minWidth: "fit-content",
    },
    "& .MuiTabs-flexContainer": {
      // gap:'0.5rem',
    },
  },
  tabbtns: {
    backgroundColor: `${style_exports.shade_color1}!important`,
    borderRadius: "0.5rem 0.5rem 0rem 0rem!important",
    textTransform: "none!important",
    width: "11rem", //"32.5%",
    height: "2.5rem!important",
    minHeight: "1rem!important",
    fontSize: "18px",
    fontWeight: "400!important",
    marginLeft: "0.5rem!important",
    "@media screen and (min-width: 300px) and (max-width: 699px)": {
      height: "2.5rem!important",
    },
  },
  cardtile: {
    backgroundColor: "#E3E3E3",
    borderRadius: "0.5rem",
    padding: "0rem 1rem",
    height: "40px",
  },
  casting_tile: {
    backgroundColor: "#AEECFF",
    borderRadius: "0.5rem",
    padding: "0rem 1rem",
    height: "40px",
  },
  spantextresponsive: {
    fontSize: "100%",
    "@media (min-width: 600px) and  (max-width: 680px) ": {
      fontSize: "84%",
    },
    "@media (min-width: 300px) and  (max-width: 599px) ": {
      fontSize: "80%",
    },
  },
});
export const MaterialUISwitch = styled(Switch)(
  ({ theme, checked, disabledData, ...props }) => ({
    width: 50,
    height: props?.height ? props?.height : 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(25px)",
        "& .MuiSwitch-thumb:before": {},
        "& + .MuiSwitch-track": {
          height: "5.94px",
          width: "35px",
          opacity: 1,
          backgroundColor: "#D9D9D9",
        },
      },
    },
   
    "& .MuiSwitch-thumb": {
      backgroundColor: disabledData ? "#636363" : checked ? "#04BEF8" : "#000000",
      width: props?.thumbWidth ? props?.thumbWidth : 20,
      position: "absolute",
      top: "-2",
      height: props?.thumbHeight ? props?.thumbHeight : 20,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      },
    },
    "& .MuiSwitch-track": {
      height: "5.94px",
      width: "35px",
      opacity: 1,
      backgroundColor: "#D9D9D9",
    },
    // "&.Mui-disabled": {
    //   color: "red!important",
    //   "& + .MuiSwitch-track": {
    //     backgroundColor: "red!important",
    //     // opacity: 1,
    //   }
    // }
  })
);
export const albumstyles = makeStyles({
  borderBottomstyle: {
    borderBottom: "2px solid #d5d5d5",
    paddingBottom: "0.5rem",
    height: "100%",
  },
  cardtitle: {
    textAlign: "justify",
    width: "100%",
    wordWrap: "break-word",
    "&.two-line-truncate": {
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  albumvideoratio: {
    display: "flex",
    borderRadius: "1rem",
    overflow: "hidden",
    aspectRatio: 16 / 10,
  },
  albumplayBtn: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    cursor: "pointer",
  },
  foodchipcolor: {
    background: "white",
    color: "#009ACA",
  },
  foodchipposition: {
    position: "absolute",
    bottom: "1rem",
    left: "1rem",
  },
  favouriteicon: {
    position: "absolute",
    bottom: "1rem",
    right: "1rem",
  },
  favourtepadding: {
    padding: "0px",
  },
  favouriteiconsize: {
    width: "30px",
    height: "30px",
    padding: "0.3rem",
  },
  checkedfavouriteiconsize: {
    color: "white",
    backgroundColor: "#F2994A",
    width: "30px",
    height: "30px",
    padding: "0.3rem",
    borderRadius: "50%",
  },
  imgstylealbum: {
    objectFit: "contain",
    // border: "1px solid #d5d5d5",
    borderRadius: "1rem",
    width: "100%",
    backgroundColor: "#d9d9d9", //#F2F2F2",
  },
  videobtnstyle: {
    objectFit: "cover",
    // border: "1px solid #d5d5d5",
    borderRadius: "1rem",
    width: "100%",
  },
  videobtnstylecontain: {
    objectFit: "contain",
    // border: "1px solid #d5d5d5",
    borderRadius: "1.5rem",
    width: "100%",
  },
  iconsize: {
    width: "25px",
    height: "25px",
  },
});
export const Dashboard_styles = makeStyles({
  notextwrapstyle: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexWrap: "wrap",
  },
  descriptionstyle: {
    textAlign: "justify",
    width: "100%",
    wordWrap: "break-word",
    "&.two-line-truncate": {
      display: "-webkit-box",
      WebkitLineClamp: 5,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  basicinfobtnstyle: {
    width: "10rem!important",
    height: "2.5rem",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "600",
    color: "#04BEF8",
    borderColor: "#04BEF8",
  },
  profileimg: {
    borderRadius: "20px",
    width: "100%",
    aspectRatio: 16 / 16,
    // height: "100%",
    objectFit: "cover",
    // maxHeight: "250px",
    height: "auto",
    "@media screen and (max-width: 599px)": {
      width: "220px",
      height: "220px",
      alignSelf: "center",
    },
  },
  plantext: {
    fontSize: "16px",
    fontWeight: 700,
    "@media (min-width: 300px) and  (max-width: 450px) ": {
      fontSize: "12px",
    },
  },
  pricetext: {
    fontSize: "16px!important",
    fontWeight: 700,
    "@media (min-width: 300px) and  (max-width: 450px) ": {
      fontSize: "16px",
    },
  },
  subplancard: {
    width: "254px !important",
    "@media (min-width: 700px) and  (max-width: 899px) ": {
      width: "250px !important",
    },
    "@media (min-width: 600px) and  (max-width: 699px) ": {
      width: "250px !important",
    },
    "@media (min-width: 300px) and  (max-width: 599px) ": {
      width: "100% !important",
    },
  },
  subtxt_styles: {
    fontSize: "17px",
    fontWeight: 700,
    "@media (min-width: 600px) and  (max-width: 615px) ": {
      fontSize: "15px",
    },
  },
  tile_styles: {
    height: "112px",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0.5rem",
  },
  subdtxt_14: {
    fontSize: "14px",
    fontWeight: 700,
    "@media screen and (max-width: 599px)": {
      fontSize: "10px",
    },
  },
  browsebtn: {
    background: `${style_exports.skyblue_color}`,
    borderRadius: "2rem",
    color: `${style_exports.btn_txtcolor}`,
    fontWeight: "600",
    padding: "0.5rem",
    margin: "1rem",
    width: "221px",
    height: "42px",
    fontSize: "16px",
    textAlign: "center",
  },
});
export const Pricing_plans = makeStyles({
  planstext: {
    fontSize: "30px",
    lineHeight: "48px",
    paddingLeft: "1.5rem",
    color: `${style_exports.shade_color}`,
    fontWeight: "300",
    "@media (min-width: 300px) and  (max-width: 599px) ": {
      fontSize: "20px",
    },
  },
  btnwidth: {
    // width: "10rem",
    "@media (min-width: 300px) and  (max-width: 599px) ": {
      // width: "7rem"
    },
  },
  plansedittext: {
    fontWeight: "700",
    lineHeight: "31px",
    fontSize: "22px",
    paddingLeft: "1.5rem",
    color: `${style_exports.shade_color}`,
  },
  plancentertext: {
    // fontWeight: "300",
    lineHeight: "51px",
    fontSize: "30px",
    color: `${style_exports.shade_color}`,
    "@media (min-width: 300px) and  (max-width: 599px) ": {
      fontSize: "18px",
    },
  },
  plansspantext: {
    fontWeight: "700",
    color: `${style_exports.shade_color} !important`,
  },
  subplanstext: {
    fontWeight: "700",
    lineHeight: "31px",
    fontSize: "16px",
    color: `${style_exports.shade_color}`,
  },
  lable: {
    fontSize: "20px",
    fontWeight: 700,
  },
});
export const howitworkstyle = makeStyles({
  tablisted: {
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTab-root": {
      color: "#333333",
      // fontWeight:'600',
      borderRadius: "2.3rem",
      border: "2px solid #E0E0E0",
      padding: "16px, 24px, 16px, 24px",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#04BEF8",
      fontWeight: "600",
      borderRadius: "2.3rem",
      border: "2px solid #04BEF8",
      padding: "16px, 24px, 16px, 24px",
    },
    "& .MuiTabs-flexContainer": {
      gap: "1rem",
    },
  },
  howitworktext: {
    fontSize: "30px",
    fontWeight: 600,
    fontFamily: "open Sans",
    marginBottom: "4rem",
  },
  textTransformstyle: {
    textTransform: "none",
  },
  paddinginhowitwork: {
    padding: "2rem 0rem 1rem 0rem",
  },
});
export const howitworkvideostyle = makeStyles({
  videoheight: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  playbtnstyle: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    cursor: "pointer",
  },
  playbtnstyleindex: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
});
export const loadingstyle = makeStyles({
  spinningstyle: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loadspinningposition: {
    position: "relative",
    display: "inline-flex",
  },
});
