/** @format */

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Customizedbutton from "@components/Customizedbutton";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { getYouTubeVideoId } from "../helpers/Helperfunctions";
import CancelIcon from "@mui/icons-material/Cancel";
import CommonImage from "@components/CommonImage";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const FullViewMedia = ({ open, close, data, selectedIndex }) => {
  // let mediaType = data?.type ? data?.type : data?.contentType === 2 ? 1 : 2;
  // let mediaUrl = data?.contentType ? data?.fileUrl : "";
  let selectedIndexCount = selectedIndex;
  const [media, setMedia] = useState({
    mediaType: 0,
    mediaUrl: "",
    mediaPosition: null,
  });

  const determineMediaType = (item) => {
    if (typeof item?.type === "number") return item.type;
    return item?.type?.startsWith("image") ? 1 : 2;
  };

  const determineMediaUrl = (item) => {
    return item?.url ? item.url : URL.createObjectURL(item);
  };

  const handlePrevNext = (type) => {
    setMedia((prev) => {
      const newPosition =
        type === 1 ? prev?.mediaPosition - 1 : prev?.mediaPosition + 1;
      const newItem = data[newPosition];
      selectedIndexCount = newPosition;
      return {
        ...prev,
        mediaType: determineMediaType(newItem),
        mediaUrl: determineMediaUrl(newItem),
        mediaPosition: newPosition,
      };
    });
  };

  useEffect(() => {
    if (selectedIndex === "youtube") {
      setMedia({
        mediaType: 3,
        mediaUrl: data,
      });
    } else if (data?.length > 0) {
      const selectedItem = data[selectedIndex];
      setMedia({
        mediaType: determineMediaType(selectedItem),
        mediaUrl: determineMediaUrl(selectedItem),
        mediaPosition: selectedIndex,
      });
    } else {
      setMedia({
        mediaType: data?.type || (data?.contentType === 2 ? 1 : 2),
        mediaUrl: data?.fileUrl || "",
      });
    }
  }, []);

  useEffect(() => {
    // Function to handle keydown events
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft" && selectedIndexCount !== 0) {
        handlePrevNext(1);
      } else if (
        event.key === "ArrowRight" &&
        selectedIndexCount !== data?.length - 1
      ) {
        handlePrevNext(2);
      }
    };

    if (open && data?.length > 0 && selectedIndex !== "youtube") {
      // Add event listener when the popup is open
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      // Cleanup: Remove event listener when the component unmounts or popup closes
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={media?.mediaType === 3 ? "md" : "lg"}
        open={open}
        onClose={close}
        sx={{
          "& .MuiPaper-root": {
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
          },
        }}
      >
        {/* <HighlightOffIcon
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            position: "absolute",
            right: 0,
            top: 0,
            cursor: "pointer",
            zIndex: 100,
          }}
          onClick={close}
        /> */}
        <CancelIcon
          sx={{
            // width: "1.2rem",
            color: "#04BEF8",
            position: "absolute",
            right: 10,
            top: 10,
            cursor: "pointer",
            zIndex: 100,
          }}
          onClick={close}
        />
        <DialogContent
          sx={{
            padding: 0,
            overflow: "hidden",
            position: "relative",
            backgroundColor: "#000000",
          }}
        >
          <>
            {media?.mediaType === 1 ? (
              <CommonImage
                src={media?.mediaUrl}
                alt=""
                style={{
                  minWidth: "100%",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            ) : media?.mediaType === 2 ? (
              <video
                src={media?.mediaUrl}
                alt=""
                style={{
                  minWidth: "100%",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
                controls
                autoPlay
              ></video>
            ) : media?.mediaType === 3 ? (
              <Grid
                sx={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                  overflow: "hidden",
                  "& iframe": {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <iframe
                  src={media?.mediaUrl}
                  allowFullScreen
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "0px",
                    objectFit: "contain",
                  }}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </Grid>
            ) : null}
            {data?.length > 0 && selectedIndex !== "youtube" ? (
              <Grid
                container
                justifyContent={"space-between"}
                gap={1}
                sx={{
                  position: "absolute",
                  // left: "0rem",
                  // bottom: "1rem",
                  width: "100%",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  padding: "0rem 1rem",
                }}
              >
                {/* <Customizedbutton
                  data={"Previous"}
                  onClick={() => handlePrevNext(1)}
                  disabled={media?.mediaPosition === 0}
                  bgcolor={media?.mediaPosition === 0 ? "#7be0ff" : undefined}
                  startIcon={<WestIcon />}
                  swidth={"7rem"}
                />
                <Customizedbutton
                  data={"Next"}
                  onClick={() => handlePrevNext(2)}
                  disabled={media?.mediaPosition === data?.length - 1}
                  bgcolor={
                    media?.mediaPosition === data?.length - 1
                      ? "#7be0ff"
                      : undefined
                  }
                  endIcon={<EastIcon />}
                  swidth={"7rem"}
                /> */}
                <>
                  <div>
                    {/* {media?.mediaPosition !== 0 && ( */}
                    <IconButton
                      disabled={media?.mediaPosition === 0}
                      onClick={() => handlePrevNext(1)}
                      sx={{
                        backgroundColor:
                          media?.mediaPosition === 0 ? "#7be0ff !important" : "#04BEF8",
                        color: "white !important",
                        "&:hover": {
                          backgroundColor:
                            media?.mediaPosition === 0 ? "#7be0ff !important" : "#04BEF8",
                          color: "white !important",
                        },
                      }}
                    >
                      <ArrowBackIosNewIcon />
                    </IconButton>
                    {/* )} */}
                  </div>
                  <div>
                    {/* {media?.mediaPosition !== data?.length - 1 && ( */}
                    <IconButton
                      disabled={media?.mediaPosition === data?.length - 1}
                      onClick={() => handlePrevNext(2)}
                      sx={{
                        justifySelf: "flex-end",
                        backgroundColor:
                          media?.mediaPosition === data?.length - 1
                            ? "#7be0ff !important"
                            : "#04BEF8",
                        color: "white !important",
                        "&:hover": {
                          backgroundColor:
                            media?.mediaPosition === data?.length - 1
                              ? "#7be0ff !important"
                              : "#04BEF8",
                          color: "white !important",
                        },
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                    {/* )} */}
                  </div>
                </>
              </Grid>
            ) : null}
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FullViewMedia;
