import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  completeprofile: 0,
  profile_data: {},
  usercurrency: {
    id: 101,
    countryName: "India",
    countryCode: "IN",
    dialing_code: "+91",
    emoji: "🇮🇳",
    currencySymbol: "₹",
    currency: "INR",
  },
  previewCompleteProfile: null,
};

const Completeprofileslice = createSlice({
  name: "completeprofile",
  initialState,
  reducers: {
    completeprofile_state(state, action) {
      state.completeprofile = action.payload;
    },
    profile_data_update(state, action) {
      state.profile_data = action.payload;
    },
    reset_complete_profiles(state, action) {
      state.completeprofile = 0;
      state.profile_data = {};
      state.usercurrency = {
        id: 101,
        countryName: "India",
        countryCode: "IN",
        dialing_code: "+91",
        emoji: "🇮🇳",
        currencySymbol: "₹",
        currency: "INR",
      };
      state.previewCompleteProfile = null
    },
    curency_update(state, action) {
      state.usercurrency = action.payload;
    },
    previewCompleteProfileUpdate(state, action) {
      state.previewCompleteProfile = action.payload;
    },
  },
});
export const completeprofile_Actions = Completeprofileslice.actions;
export default Completeprofileslice;
