import React from "react";

const WorksIcon = (props) => {
  return (
    <>
      <svg
        width="79"
        height="80"
        viewBox="0 0 79 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M39.5002 5.13818C20.2256 5.13818 4.5835 20.7803 4.5835 40.0549C4.5835 59.3294 20.2256 74.9715 39.5002 74.9715C58.7747 74.9715 74.4168 59.3294 74.4168 40.0549C74.4168 20.7803 58.7747 5.13818 39.5002 5.13818ZM26.3335 56.159C24.6814 56.159 23.097 55.5027 21.9288 54.3345C20.7606 53.1663 20.1043 51.5819 20.1043 49.9299C20.1043 48.2778 20.7606 46.6934 21.9288 45.5252C23.097 44.357 24.6814 43.7007 26.3335 43.7007C27.9856 43.7007 29.57 44.357 30.7382 45.5252C31.9064 46.6934 32.5627 48.2778 32.5627 49.9299C32.5627 51.5819 31.9064 53.1663 30.7382 54.3345C29.57 55.5027 27.9856 56.159 26.3335 56.159ZM33.271 26.8882C33.271 25.2361 33.9273 23.6517 35.0955 22.4835C36.2637 21.3153 37.8481 20.659 39.5002 20.659C41.1522 20.659 42.7367 21.3153 43.9049 22.4835C45.073 23.6517 45.7293 25.2361 45.7293 26.8882C45.7293 28.5403 45.073 30.1247 43.9049 31.2929C42.7367 32.4611 41.1522 33.1174 39.5002 33.1174C37.8481 33.1174 36.2637 32.4611 35.0955 31.2929C33.9273 30.1247 33.271 28.5403 33.271 26.8882ZM52.6668 56.159C51.0148 56.159 49.4303 55.5027 48.2621 54.3345C47.0939 53.1663 46.4377 51.5819 46.4377 49.9299C46.4377 48.2778 47.0939 46.6934 48.2621 45.5252C49.4303 44.357 51.0148 43.7007 52.6668 43.7007C54.3189 43.7007 55.9033 44.357 57.0715 45.5252C58.2397 46.6934 58.896 48.2778 58.896 49.9299C58.896 51.5819 58.2397 53.1663 57.0715 54.3345C55.9033 55.5027 54.3189 56.159 52.6668 56.159Z"
          stroke="#484848"
          stroke-width="4"
        />
      </svg>
    </>
  );
};

export default WorksIcon;
