import { createSlice } from "@reduxjs/toolkit";
/**
 * estimation_type
 * -->0:neutral and the screen is in list
 * -->1:Each estimation details view
 * -->2:Meetingplans list
 */
const initialState = {estimation_type:0,projectid:{}};

const Estimationslice = createSlice({
  name: "estimationlist",
  initialState,
  reducers: {
    estimation_screens(state,action){
        state.estimation_type=parseInt(action.payload);
    },
    project_selection(state,action){
      state.projectid = action.payload
    }
  }
});
export const Estimationsliceactions = Estimationslice.actions;
export default Estimationslice;
