import { makeStyles } from "@mui/styles";
export  const forgotpwdst = makeStyles ({
    forgettext:{
        fontSize:"20px",
        fontWeight:'700',
        
    },
    emailtext:{
        color: "#333333",
        fontSize: "16px",
        fontWeight: 600
    },
});