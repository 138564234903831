import { Dialog, Grid, IconButton } from "@mui/material";
import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Customizedbutton from "@components/Customizedbutton";
import { account_restoration } from "@services/Userservices";
import { customEnqueueSnackbar } from "@constants/Jsxconstants";
import CancelIcon from "@mui/icons-material/Cancel";
import { Portfolio_Styles } from "../styles/Dashboardstyles";

const Restoreaccount = (props) => {
  const { open, close, restoreaccount,loginres } = props;
  const JSX_styles = Portfolio_Styles();

/**
   * Restoring the deactivated account by verifying the registered email and mobile number
 */
    const handlerestore = async()=>{
        try{
            const req = { userId: loginres?.id };
            const restore_res = await account_restoration(req);
            if (restore_res?.status) {
                restoreaccount();
                close();
            } else {
              customEnqueueSnackbar(restore_res?.message, { variant: "error" });
            }
        }
        catch(err){

        }
    }
  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        sx: {
          position: "fixed",
          top: "30%",
          borderRadius: "2rem",
          m: 0,
          minHeight: 150,
          maxHeight: 400,
          width: 400,
          maxWidth: 400,
          padding: "0.5rem 0.5rem 1.5rem 0.5rem",
          display: "flex",
          alignItems: "center",
          backdropFilter: "blur(15px)",
          "@media screen and (max-width: 599px)": {
            width: 260,
            maxWidth: 260,
          },
        },
      }}
    >
      <Grid container justifyContent={"flex-end"}>
      <IconButton
              sx={{ "&:hover": { background: "none" }, color: "none" }}
              onClick={ close}
            >
              {/* <HighlightOffIcon sx={{ color: "rgba(0, 0, 0, 0.6)" }} /> */}
              <CancelIcon
          className={JSX_styles.closeiconstyle}
          onClick={close}
        />
            </IconButton>      </Grid>
      <Grid container direction="column" spacing={3} alignItems="center">
        <Grid item textAlign={"center"} fontWeight={"500"} sx={{fontSize: {md: "22px",sm: "22px",}}}>
        Your account is Deactivated.<br/>
          Do you want to Restore it
        </Grid>
        <Grid item container justifyContent={"center"}>
          
          {/* <Grid item md={5}> */}
            <Customizedbutton
              data={"Restore"}
              swidth="130px"
              sheight="46px"
              styles={{ borderRadius: "2rem" }}
              bgcolor="#04BEF8"
              onClick={handlerestore}
            />
          {/* </Grid> */}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Restoreaccount;
