import {
  Dialog,
  Grid,
  IconButton,
  Typography,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import Customizedinput from "@components/Customizedinput";
import Customizedbutton from "@components/Customizedbutton";
import { forgotpwdst } from "@styles/Modelstyles";
import Countrycode from "@models/Countrycode";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import { style_exports } from "@exports/style_exports";
import { Regex_exports } from "@exports/Regex_eports";
import { useDispatch, useSelector } from "react-redux";
import { LoginActions } from "@store/Loginslice";
import { mobilechecking } from "@validations/Commonvalidations";
import { customEnqueueSnackbar } from "@constants/Jsxconstants";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  edit_email,
  edit_mobile,
  forgot_password,
} from "../services/Userservices";
import CancelIcon from "@mui/icons-material/Cancel";
import { Portfolio_Styles } from "../styles/Dashboardstyles";

const Forgotpwd = (props) => {
  /**
   * type->1 Edit mobile number
   * This model is user for both password and edit mobile number
   */
  const { open, close, otpcallback, type } = props;
  const forgotpassword = forgotpwdst();
  const JSX_styles = Portfolio_Styles();

  const dispatch = useDispatch();
  const user_data = useSelector((state) => state.authentication.data);
  // states initialization
  const [mail, setMail] = useState("");
  const [country_code, setCountry_code] = useState(false);
  const [frgt_mobile, setFrgt_mobile] = useState(type === 2 ? 0 : type);
  const [selcountry_code, setSelcountry_code] = useState({
    id: 101,
    countryName: "India",
    countryCode: "IN",
    dialing_code: "+91",
  });
  const handlecountry_selection = (country) => {
    setSelcountry_code(country);
  };
  const handleforgotpwd = async () => {
    try {
      if (mail === "") {
        customEnqueueSnackbar("Enter your email or mobile.", { variant: "warning" });
        return;
      }
      const frgt_req = {
        emailOrPhone: mail,
        type: frgt_mobile ? 2 : 1,
        countryCode: frgt_mobile ? selcountry_code?.id : "",
      };
      const forgot_res = await forgot_password(frgt_req);
      if (forgot_res?.status) {
        otpcallback(1, {
          mail: mail,
          countrycode: frgt_mobile ? selcountry_code : {},
          userid: forgot_res?.data?.id,
        });
        dispatch(LoginActions.userlogin([0, forgot_res?.data]));
        close();
      } else {
        customEnqueueSnackbar(forgot_res?.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleinputchange = (event) => {
    const { name, value } = event.target;
    if (!type) {
      if (Regex_exports.mobile_regex.test(value) && value.length >= 3) {
        setFrgt_mobile(1);
      } else {
        setFrgt_mobile(0);
      }
    }
    setMail(value.trim());
  };
  /**
   * Handling for edit mobile number for the application
   * @returns
   */
  const handleEditNumber = async () => {
    try {
      if (mobilechecking(mail, "Mobile number")) return;
      if (user_data?.phoneNumber === mail) {
        customEnqueueSnackbar("Enter new mobile number.", { variant: "warning" });
        return;
      }
      const edit_req = {
        userId: user_data?.id,
        phoneNumber: mail,
        countryCode: selcountry_code?.id,
      };
      const edit_res = await edit_mobile(edit_req);
      if (edit_res?.status) {
        otpcallback(2, { phn: mail, countrycode: selcountry_code });
        close();
      } else {
        customEnqueueSnackbar(edit_res?.message, { variant: "error" });
      }
    } catch (err) {
      customEnqueueSnackbar(err, { variant: "warning" });
    }
  };
  /**Handling edit email from account setting screen */
  const handleEditEmail = async () => {
    try {
      if (mail === "") {
        customEnqueueSnackbar("Enter your email.", { variant: "warning" });
        return;
      }
      if (user_data?.email === mail) {
        customEnqueueSnackbar("Enter new email.", { variant: "warning" });
        return;
      }
      const edit_req = {
        userId: user_data?.id,
        email: mail,
      };
      const edit_res = await edit_email(edit_req);
      if (edit_res?.status) {
        otpcallback(5, { phn: mail });
        close();
      } else {
        customEnqueueSnackbar(edit_res?.message, { variant: "error" });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Countrycode
        open={country_code}
        close={() => setCountry_code(false)}
        countrycode_selection={handlecountry_selection}
      />
      <Dialog
        open={open}
        onClose={close}
        // sx={{height: "400px", "& .MuiDialog-paper":{ borderRadius:"25px",background: "#FFFFFFCC"}}}
        PaperProps={{
          sx: {
            position: "fixed",
            top: "30%",
            borderRadius: "25px",
            background: "#FFFFFFCC",
            // left: !props.right ? `${props.left}%` : '',
            // right: props.right ? `${props.right}%` : '',
            m: 0,
            minHeight: 200,
            maxHeight: 500,
            width: 400,
            maxWidth: 500,
            padding: "0rem",
            display: "flex",
            alignItems: "center",
            backdropFilter: "blur(15px)",
            "@media screen and (max-width: 599px)": {
              minHeight: 200,
              maxHeight: 500,
              width: 290,
              maxWidth: 290,
            },
          },
        }}
      >
        {/* <DialogTitle> */}
        <Grid container mt={4} alignItems={"center"}>
          <Grid item xs textAlign={"center"}>
            <span className={forgotpassword.forgettext}>
              {type === 1
                ? "Edit Number"
                : type === 2
                ? "Edit Email"
                : "Forgot Password"}
            </span>
          </Grid>
          {/* <Grid item md={1} sm={1} xs={"auto"}> */}
            <IconButton
              onClick={close}
              sx={{
                position: "absolute",
                top: 2,
                right: 2,
                color: "#04BEF8",
              }}
            >
              {/* <HighlightOffIcon
                sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                // onClick={close}
              /> */}
              <CancelIcon
              // className={JSX_styles.closeiconstyle}
              // onClick={close}
              />
            </IconButton>
          {/* </Grid> */}
        </Grid>
        {/* </DialogTitle> */}
        {/* <DialogContent> */}
        <Grid container mt={5} pl={3} pr={3}>
          <Typography variant={"body1"} className={forgotpassword.emailtext}>
            Enter {type === 0 ? "registered" : ""}{" "}
            {type === 1
              ? "Mobile Number"
              : type === 2
              ? "Email"
              : "Email ID/Mobile"}
          </Typography>
        </Grid>
        <Grid container mt={1} pl={3} pr={3}>
          <Customizedinput
            size={"medium"}
            decapitalize={true}
            placeholder="Enter here"
            fullWidth
            value={mail}
            onChange={handleinputchange}
            name="email"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                type === 1
                  ? handleEditNumber()
                  : type === 2
                  ? handleEditEmail()
                  : handleforgotpwd();
              }
            }}
            InputProps={
              frgt_mobile && {
                startAdornment: (
                  <InputAdornment
                    position="start"
                    onClick={() => setCountry_code(true)}
                  >
                    <Grid container spacing={0.3} mt={0.1}>
                      <Grid
                        item
                        md={6}
                        sm={6}
                        xs={6}
                      >{`${selcountry_code?.dialing_code}`}</Grid>
                      <Grid
                        item
                        md={4}
                        sm={4}
                        xs={4}
                        sx={{
                          marginLeft:
                            selcountry_code?.dialing_code.length > 4
                              ? "5px"
                              : "0px",
                        }}
                      >
                        <KeyboardArrowDownSharpIcon
                          sx={{
                            color: style_exports?.skyblue_color,
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={1}
                        mt={1}
                        sx={{
                          borderRight: `1px solid ${style_exports?.shade_color}`,
                          height: "20px",
                          marginLeft: "4px",
                        }}
                      ></Grid>
                    </Grid>
                  </InputAdornment>
                ),
              }
            }
          />
        </Grid>
        <Grid container mt={3} mb={3} justifyContent={"center"}>
          <Grid item md={4} sm={6} xs={"auto"}>
            <Customizedbutton
              data={"Submit"}
              // swidth={"9rem"}
              // sheight={"2rem"}
              size={"xxl"}
              onClick={
                type === 1
                  ? handleEditNumber
                  : type === 2
                  ? handleEditEmail
                  : handleforgotpwd
              }
            />
          </Grid>
        </Grid>
        {/* </DialogContent> */}
      </Dialog>
    </>
  );
};

export default Forgotpwd;
