import { createSlice } from "@reduxjs/toolkit";
/**
 * package_creation
 * 0->packages list
 * 1->package_creation screen
 */
const initialState = {package_creation:0};

const Adminsubscriptionslice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
       change_package_creation(state,action){
        state.package_creation = parseInt(action.payload);
       }
  },
});
export const Adminsubscriptionactions = Adminsubscriptionslice.actions;
export default Adminsubscriptionslice;
