import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Signuporlogin from "@pages/Signuporlogin";
import Userlayout from "@layouts/Userlayout";

const Signupprivaterouting = ({ children }) => {
  const location = useLocation();
  const login_status = useSelector(
    (state) => state.authentication.login_status
  );
  const profile_data = useSelector(
    (state) => state.complete_profile.profile_data
  );
  const user_data = useSelector((state) => state?.authentication?.data);
  const skip_status = useSelector(
    (state) => state?.authentication?.skip_status
  );
  let login_authenticated = false;
  let redirectionurl = "/home";
 
  if( !user_data?.profileStatus &&
    login_status &&
    location?.pathname === "/mocalogin" 
    &&
    !skip_status){
      redirectionurl = "/mocalogin/accountcreated";
    login_authenticated = true;
    }
  else if (
    !user_data?.profileStatus &&
    login_status &&
    location?.pathname === "/mocalogin" 
    &&
    skip_status
  ) {
    redirectionurl = "/mocalogin/profilecreation";
    login_authenticated = true;
  }
  else if (user_data?.profileStatus) {
    login_authenticated = false;
  }
  return login_authenticated ? (
    <Navigate to={redirectionurl} />
  ) :
   (
    <Routes>
      {" "}
      <Route path="" element={<Userlayout />}>
        <Route path="/mocalogin" element={<Signuporlogin />} />
      </Route>
    </Routes>
  );
};

export default Signupprivaterouting;
