import { createSlice } from "@reduxjs/toolkit";
/**
 * subadmin_type
 * 0->subadmins list
 * 1->subadmin creation
 * 2->subamdin details view
 */
const initialState = {subadmin_type:0};

const Subadminslice = createSlice({
  name: "subadmin",
  initialState,
  reducers: {
        change_subadmin_type(state,action){
            state.subadmin_type=parseInt(action.payload)
        }
  },
});
export const Subadminactions = Subadminslice.actions;
export default Subadminslice;
