import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  category: {},
  sub_category: {},
  supersub_category: {},
  selectedprofile: {},
  location_profile: {},
  data: {},
  searchstr: "",
};

const Allcategoriesslice = createSlice({
  name: "allcategories",
  initialState,
  reducers: {
    /**
     *
     * @param {initial state} state
     * @param {array} action
     * action.payload[0]=>{
     * 1->category type
     * 2->sub_category type
     * 3->Super sub category
     * }
     */
    searched_types(state, action) {
      if (action.payload[0] === 1) {
        state.category = action?.payload[1];
        state.sub_category = {};
        state.supersub_category = {};
        state.data = {};
        state.setSearchstr = "";
      } else if (action.payload[0] === 2) {
        state.sub_category = action.payload[1];
        state.supersub_category = {};
        state.data = {};
        state.setSearchstr = "";
      } else if (action.payload[0] === 3) {
        state.supersub_category = action.payload[1];
        state.data = {};
        state.setSearchstr = "";
      } else {
        state.selectedprofile = action.payload[1];
      }
    },
    searched_datatype(state, action) {
      state.category = action.payload[0];
      state.sub_category = action.payload[1];
      state.supersub_category = action.payload[2];
    },
    searched_data(state, action) {
      state.data = action.payload;
    },
    setSearchstr(state, action) {
      state.searchstr = action.payload;
    },
    location_profiles(state, action) {
      state.location_profile = action.payload;
    },
  },
});
export const all_categoriesactions = Allcategoriesslice.actions;
export default Allcategoriesslice;
