import { Grid, Skeleton, Stack } from "@mui/material";
import { Request_styles } from "@styles/Requests_styles";

const CastingCallRequestCard = () => {
  const request_styles = Request_styles();
  return (
    <Grid
      item
      container
      direction={"column"}
      className={request_styles.paddingwidthstyle}
      rowGap={1}
      position={"relative"}
    >
      {/* Skeleton for the expired text */}
      <Skeleton
        variant="text"
        width="20%"
        height={20}
        sx={{
          position: "absolute",
          top: "2px",
          left: "5px",
          backgroundColor: "transparent",
        }}
      />

      {/* Skeleton for the movie reel image */}
      <Grid item textAlign={"center"} mt={1.5}>
        <Skeleton variant="circular" width={50} height={50} />
      </Grid>

      {/* Skeleton for the project name */}
      <Grid item textAlign={"center"} className={request_styles.textoverstyle}>
        <Skeleton variant="text" width="60%" height={28} />
      </Grid>

      {/* Skeleton for the Favorited section */}
      <Grid item>
        <Grid
          container
          className={request_styles.casting_tile}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item sx={{ fontSize: "14px" }}>
            <Skeleton variant="text" width={80} height={20} />
          </Grid>
          <Grid item>
            <Skeleton variant="text" width={40} height={20} />
          </Grid>
        </Grid>
      </Grid>

      {/* Skeleton for the Hold section */}
      <Grid
        item
        container
        sx={{ paddingBottom: "0.5rem" }}
        justifyContent={"space-between"}
      >
        <Grid item md={"auto"} sm={"auto"} xs={"auto"}>
          <Stack direction={"row"} alignItems={"center"}>
            <Skeleton variant="text" width={60} height={20} />
          </Stack>
        </Grid>

        {/* Skeleton for Edit and Delete buttons */}
        <Grid
          item
          container
          justifyContent={"end"}
          md={"auto"}
          sm={12}
          xs={12}
          textAlign={"right"}
        >
          <Stack direction={"row"} spacing={1}>
            <Skeleton variant="circular" width={25} height={25} />
            <Skeleton variant="circular" width={25} height={25} />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CastingCallRequestCard;
