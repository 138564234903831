import { createSlice } from "@reduxjs/toolkit";
/**
 * call_types
 * -->0:neutral and the screen is in list
 * -->1:casting call create
 * -->2:casting call view
 * -->3:Create competition screen
 * -->4:competitions details view
 * -->5:competitions all applications list view
 * -->6:competitions new applications list view 
 * -->7:Each applicant details in competitions
 */
const initialState = {call_type:0};

const Admincastingcallslice = createSlice({
  name: "casingcall",
  initialState,
  reducers: {
    castingcalls_screens(state,action){
        state.call_type=action.payload;
    }
  }
});
export const Admincastingcallactions = Admincastingcallslice.actions;
export default Admincastingcallslice;
