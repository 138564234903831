/** @format */

import React, { useState } from "react";
import Customizedinput from "@components/Customizedinput";
import Customizedbutton from "@components/Customizedbutton";
import { forgotpwdst } from "@styles/Modelstyles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Userapiservice from "@services/Userapiservice";
import { useDispatch, useSelector } from "react-redux";
import { LoginActions } from "@store/Loginslice";
import { useNavigate } from "react-router-dom";
import { style_exports } from "@exports/style_exports";
import { customEnqueueSnackbar } from "@constants/Jsxconstants";
import { StorageClient } from "@helpers/Helperfunctions";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { otp_resend, verify_editmail_otp } from "../services/Userservices";
import { Userunblock_activated } from "../Firebase/Firebaseservices";
import { Portfolio_Styles } from "../styles/Dashboardstyles";
import CancelIcon from "@mui/icons-material/Cancel";

const Otpverification = (props) => {
  /**
   * @params {integer} -type
   * @params {boolean} -open
   * @params {function} -close
   *
   * 0->Normal login coming from register and login screens
   * 1->Coming from forgot pwd screen
   * 2->Coming from edit mobile number
   * 3->Two factor authentication enables
   * 4->Deactivation account
   * 5->Coming from edit email
   */
  const { open, close, type, reset_pwdcallback, number, loginres } = props;
  const forgotpassword = forgotpwdst();
  const JSX_styles = Portfolio_Styles();

  const user_data = useSelector((state) => state.authentication.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  // Verify otp api call
  const Verifyotp = async () => {
    try {
      if (otp === "") {
        customEnqueueSnackbar(
          "Oops! You forgot to enter your OTP. Please provide it.",
          { variant: "warning" }
        );
        return;
      }
      let endpoint = "verifyMobileOTP";
      if (type === 1) {
        endpoint = "verifyForgotPasswordOTP";
      }
      let otp_req = {
        userId: loginres?.id, //user_data?.id ?? loginres?.id,
        [type === 1 ? "otp" : "mobileotp"]: otp,
      };
      if (type === 2) {
        otp_req.editMobileNo = 1;
        otp_req.phoneNumber = number.phn;
        otp_req.countryCode = number.countrycode?.id;
      }
      if (type === 3) {
        otp_req.editMobileNo = 2;
      }
      const otp_res = await Userapiservice(
        endpoint,
        otp_req,
        "POST",
        "application/json"
      );
      if (otp_res?.status) {
        switch (type) {
          case 1:
            reset_pwdcallback(1);
            break;
          case 2:
            customEnqueueSnackbar("Phone number updated successfully", {
              variant: "success",
            });
            break;
          case 3:
            dispatch(LoginActions.userlogin([1, loginres]));
            StorageClient.setItem("token", loginres?.token);
            customEnqueueSnackbar("User Loggedin Succesfully", {
              variant: "success",
            });
            navigate("/");
            break;
          case 4:
            switch (loginres?.socialogin) {
              case 0:
                await Userunblock_activated(loginres?.id);
                reset_pwdcallback(2);
                break;
              case 1:
                reset_pwdcallback(3);
                break;
            }
            break;
          default:
            StorageClient.setItem("login_status", 1);
            StorageClient.setItem("token", loginres?.token); //user_data?.token
            dispatch(LoginActions.userlogin([1, loginres])); //user_data
            navigate("/mocalogin/accountcreated");
            break;
        }

        close();
      } else {
        customEnqueueSnackbar(otp_res?.message, { variant: "warning" });
      }
    } catch (err) {
      console.log(err);
      customEnqueueSnackbar(err, { variant: "error" });
    }
  };

  const verifyedit_emailotp = async () => {
    try {
      let req = {
        userId: user_data?.id,
        emailOtp: otp,
        email: number?.phn,
      };
      const verifyeditemail_otp = await verify_editmail_otp(req);
      if (verifyeditemail_otp?.status) {
        close();
      } else {
        customEnqueueSnackbar(verifyeditemail_otp?.message, { variant: "warning" });
      }
    } catch (err) {
      console.log(err);
    }
  };
  // Resend otp api call
  const resendotp = async () => {
    try {
      let resend_type =
        type === 1 ? 1 : type === 3 ? 2 : [2, 5].includes(type) ? 3 : 0;
      let otp_sendval = [0, 4].includes(type)
        ? loginres?.phoneNumber //user_data?.phoneNumber
        : number?.phn;
      let val_type = isNaN(otp_sendval) ? 1 : 2;
      let req = {
        userId: loginres?.id,
        emailOrMobile: otp_sendval,
        countryCode: [0, 4].includes(type)
          ? loginres?.countryCode?.id //user_data?.countryCode?.id
          : number?.countrycode?.id,
        type: val_type,
        resendType: resend_type,
      };

      const resendotp_res = await otp_resend(req);
      if (resendotp_res?.status) {
        let otp_sent_type = [1, 2, 3].includes(type)
          ? number?.phn
          : loginres?.phoneNumber;
        customEnqueueSnackbar(
          `Otp Sent to your registered ${
            isNaN(otp_sent_type) ? "Email" : "Mobile Number"
          }`,
          {
            variant: "success",
          }
        );
      } else {
        customEnqueueSnackbar(resendotp_res?.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={type === 3 ? "" : close}
      PaperProps={{
        sx: {
          minWidth: "360px",
          maxWidth: "100%",
          borderRadius: "25px",
          background: "#FFFFFFCC",
          backdropFilter: "blur(15px)",
          "@media screen and (min-width: 200px) and (max-width: 399px)": {
            minWidth: "290px",
            maxWidth: "100%",
          },
        },
      }}
    >
      <DialogTitle>
        <Grid container position={"relative"}>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <h4 className={forgotpassword.forgettext}>OTP verification</h4>
          </Grid>
          <Grid sx={{ position: "absolute", right: -15, top: 0 }}>
            {/* sx={{ position: "absolute", right: -15, top: 0 }} */}
            {/* <IconButton
              sx={{ "&:hover": { background: "none" }, color: "none" }}
              onClick={close}
            > */}
              {/* <HighlightOffIcon sx={{ color: "rgba(0, 0, 0, 0.6)" }} /> */}
              {/* <CancelIcon
          // className={JSX_styles.closeiconstyle}
        /> */}
            {/* </IconButton> */}
            <CancelIcon
            sx={{
              position: "absolute",
              top: -5,
              right: -1,
              color: "#04BEF8",
            }}
            // className={JSX_styles.closeiconstyle}
            onClick={close}
          />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Typography variant={"body1"}>
            OTP send to{" "}
            {[1, 2, 3, 5].includes(type) ? number?.phn : loginres?.phoneNumber}
          </Typography>
        </Grid>
        <Grid container mt={1}>
          <Customizedinput
            size={"medium"}
            placeholder="Enter otp here"
            fullWidth
            name="otp"
            value={otp}
            onChange={(e) => {
              if (!isNaN(e.target.value)) {
                setOtp(e.target.value.trim());
              }
            }}
            bgcolor={"#FFFFFF"}
            sheight={"4rem"}
          />
        </Grid>
        <Grid
          container
          direction="column"
          mt={3}
          mb={3}
          justifyContent={"center"}
          spacing={1}
        >
          <Grid
            item
            container
            justifyContent={"center"}
            md={6.5}
            sm={6}
            xs={12}
          >
            <Customizedbutton
              data={"Submit"}
              swidth={"9rem"}
              sheight={"3rem"}
              onClick={type === 5 ? verifyedit_emailotp : Verifyotp}
            />
          </Grid>
          <Grid item container justifyContent={"center"}>
            Didn't receive OTP ?
            <span
              style={{
                fontWeight: "700",
                color: style_exports.skyblue_color,
                cursor: "pointer",
                textDecorationLine: "underline",
                paddingLeft: "0.4rem",
              }}
              onClick={() => resendotp()}
            >
              Resend
            </span>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Otpverification;
