import React, { useContext, useEffect, useState } from "react";
import { Aboutusstyles } from "@styles/Terms&conditions";
import { Container, Grid, Stack } from "@mui/material";
import aboutusimg from "@images/aboutusimg.png";
import quote1 from "@images/quote1.png";
import quote2 from "@images/quote2.png";
import aboutussection from "@images/aboutussection.png";
import Vishal from "@images/Vishal.png";
import RohanSharma from "@images/RohanSharma.png";
import VarshithaHasini from "@images/VarshithaHasini.png";
import kasyapVihan from "@images/kasyapVihan.png";
import { getAboutUsApi } from "../services/Commonservices";
import { customEnqueueSnackbar } from "@constants/Jsxconstants";
import Loadingcontext from "@components/parts/Loadingprovider";
import CommonImage from "@components/CommonImage";
import { scrollTop } from "../helpers/Helperfunctions";

const Aboutus = () => {
  const [aboutUsContent, setAboutUsContent] = useState();
  const { setLoading } = useContext(Loadingcontext);

  const getAboutUS = async () => {
    try {
      setLoading(true);
      const res = await getAboutUsApi({});
      if (res?.status) {
        setAboutUsContent(res?.data);
      } else {
        customEnqueueSnackbar(res?.message, { variant: "error" });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    scrollTop()
    getAboutUS();
  }, []);
  const classes = Aboutusstyles();
  return (
    <>
      <Grid container className={classes.aboutusbg}>
        <h3 className={classes.abouttextsection}>ABOUT US</h3>
      </Grid>
      <Container maxWidth={"xl"}>
        <Grid container mt={2} direction={"column"} spacing={5}>
          <Grid
            item
            className="no_margin_child"
            dangerouslySetInnerHTML={{ __html: aboutUsContent?.content }}
          />
        </Grid>
      </Container>
      {/* founder section */}
      <Grid container className={classes.stylered} mt={5}>
        <Grid
          item
          container
          pb={3}
          md={11.5}
          sm={11.5}
          className={classes.whitebottomradius}
        >
          <Grid item md={0.5} sm={0.5}>
            {" "}
          </Grid>
          <Container maxWidth="xl">
            <Grid container className={classes.gridresponsive_aboutus}>
              <Grid
                item
                container
                // sx={{ height: "620px", width: "506px" }}
                direction={"column"}
                md={4}
                sm={4}
                xs={12}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {" "}
                <CommonImage
                  src={aboutUsContent?.image}
                  className={classes.imageresponsive_aboutus}
                  alt="aboutus"
                />
              </Grid>
              <Grid
                item
                container
                md={7}
                sm={7}
                xs={12}
                // gap={}
                sx={{ borderRadius: "50px" }}
                className={classes.stylewhite}
              >
                <Grid item md={1} sm={1} xs={2}>
                  <CommonImage src={quote1} alt="quote" />
                </Grid>
                <Grid
                  item
                  spacing={3}
                  container
                  direction={"column"}
                  md={9}
                  sm={9}
                  xs={8}
                  mt={5}
                >
                  <Grid item>
                    <span className={classes.abouttext12}>
                      {/* Whether you're a movie creator, artist, or vendor,
                      YourMoca is the perfect place to connect with other
                      creatives and to grow your career. */}
                      {aboutUsContent?.quote}
                    </span>
                  </Grid>
                  <Grid item pb={3}>
                    <span className={classes.shadetext}>
                      <i>
                        {/* Founder and Chairman */}
                        {aboutUsContent?.author}
                      </i>
                    </span>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={1}
                  sm={1}
                  xs={2}
                  container
                  alignItems={"flex-end"}
                >
                  <CommonImage src={quote2} alt="quote" />
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid
          item
          p={0}
          m={0}
          md={0.5}
          sm={0.5}
          sx={{ display: { md: "block", sm: "block", xs: "none" } }}
          className={classes.stylewhite}
        >
          <Grid
            item
            md={12}
            sm={12}
            mt={2}
            className={classes.redtopradius}
          ></Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item container md={0.9}>
          <Grid item md={6} sm={6}></Grid>
          <Grid item md={6} sm={6} className={classes.bordertopbottom}></Grid>
        </Grid>
        <Grid item container md={11.1} className={classes.redbottom}>
          <Grid item md={3} sm={3} pt={3} pb={3} pl={5}>
            <CommonImage
              src={aboutussection}
              width={"100%"}
              height={"100%"}
              alt="aboutussection"
            />
          </Grid>
          <Grid
            item
            container
            direction={"column"}
            md={7}
            sm={7}
            pt={3}
            pb={3}
            ml={3}
          >
          
            <Grid
              item
              className="no_margin_child"
              dangerouslySetInnerHTML={{
                __html: aboutUsContent?.whyChooseYourmoca,
              }}
            />
          </Grid>
          <Grid item md={1} sm={1}></Grid>
        </Grid>
      </Grid>
      <Container maxWidth={"xl"} md={3}>
        <Grid container mt={3} p={2} justifyContent={"center"}>
          <span className={classes.mainblacktext}>Meet Our Team</span>
        </Grid>
        <Grid container spacing={2} p={2} justifyContent={"center"}>
          {aboutUsContent?.teamDetails?.length > 0
            ? aboutUsContent?.teamDetails.map((member, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    container
                    direction={"column"}
                    spacing={2}
                    md={3}
                    sm={3}
                    xs={6}
                  >
                    <Grid item width={"100% !important"} >
                      <CommonImage
                        src={member?.imgKey}
                        alt={member?.name}
                        width={"100%"}
                        className={classes?.teamMemberImage}
                      />
                    </Grid>
                    <Stack mt={2}>
                      <Grid item textAlign={"center"} fontWeight={"bold"}>
                        {" "}
                        {member?.name}
                      </Grid>
                      <Grid
                        item
                        textAlign={"center"}
                        className={classes.shadetext}
                      >
                        <i>{member?.designation}</i>
                      </Grid>
                    </Stack>
                  </Grid>
                );
              })
            : null}

          
        </Grid>
      </Container>
    </>
  );
};

export default Aboutus;
