import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  castingCall_autoSave: 0,
  postRequest_autoSave: 0,
  autoSaveDetailsResgister: null
};

const AutoSaveSlice = createSlice({
  name: "autosave",
  initialState,
  reducers: {
    setCastingAuto(state, action) {
      state.castingCall_autoSave = action.payload;
    },
    setPost(state, action) {
      state.postRequest_autoSave = action.payload;
    },
    setAutoSaveRegister(state, action) {
      state.autoSaveDetailsResgister = action.payload;
    },
  },
});

export const AutoSaveActions = AutoSaveSlice.actions;
export default AutoSaveSlice;
