import Skeleton from '@mui/material/Skeleton';
import { Card, CardContent, Grid } from '@mui/material';
import { serviceCardStyles } from "@styles/CardStyles";

export const ServiceCardSkeleton = () => {
  const styles = serviceCardStyles();

  return (
    <Card className={styles.cardContainer}>
      <Grid position={"relative"}>
        <Skeleton
          variant="rectangular"
          className={styles.cardImage}
          height={200}
        />
        <Grid container className={styles.cardContainerflowter}>
          <Grid item>
            <Skeleton variant="text" width={60} height={30} />
          </Grid>
          <Grid item>
            <Skeleton variant="circular" width={25} height={25} />
          </Grid>
        </Grid>
      </Grid>
      <CardContent className={styles.cardContent}>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Skeleton variant="text" width={100} />
          </Grid>
          <Skeleton variant="text" width={40} />
        </Grid>

        <Skeleton variant="text" height={40} width="100%" />
        <Skeleton variant="text" height={20} width="100%" />
        <Skeleton variant="text" height={20} width="80%" />

        <Grid>
          <Skeleton variant="rectangular" height={40} width={110} />
        </Grid>
      </CardContent>
    </Card>
  );
};
