import { createSlice } from "@reduxjs/toolkit";
/**
 * data is stored with login details either with user or admin
 * user_type -0 user login
 * usertype-1 admin login
 * usertype-2 admin logged into user account from admin panel
 */
const initialState = {
  login_status: 0,
  skip_status: 0,
  data: {},
  user_type: 0,
  privileges: [],
};

const Loginslice = createSlice({
  name: "login",
  initialState,
  reducers: {
    userlogin(state, action) {
      /**Admin data coming in object formate */
      if (!Array.isArray(action?.payload)) {
        state.data = action.payload;
        state.user_type = 1;
        state.login_status = 0;
      } else {
        /**user data getting in array formate */
        state.login_status = action.payload[0];
        state.data = action.payload[1];
        state.user_type = 0;
      }
    },
    /**admin loggin into user account */
    admin_user_login(state, action) {
      state.login_status = action.payload[0];
      state.data = action.payload[1];
      state.user_type = 2;
    },
    change_usertype(state, action) {
      state.user_type = 0;
    },
    skip_action(state, action) {
      state.user_type = 0;
      state.skip_status = action.payload[0];
    },
    resetlogin(state, action) {
      state.login_status = 0;
      state.user_type = 0;
      state.data = {};
    },
    update_twofactor(state, action) {
      state.data = { ...state.data, istwoFactorEnabled: action.payload };
    },
    subadmin_privileges(state, action) {
      const sorted = [...action?.payload].sort((a, b) => {
        if (a === b) {
          return 0;
        }
        return a < b ? -1 : 1;
      });
      state.privileges = sorted; //action.payload;
    },
    plan_upgradation(state,action){
      state.data = {...state.data,plan:{...action.payload,activeSubscription:1}}
    }
  },
});
export const LoginActions = Loginslice.actions;
export default Loginslice;
