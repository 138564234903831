import React from "react";
import { Route, Routes } from "react-router-dom";
import Aboutus from "../pages/Aboutus";
import Privacypolicy from "@pages/Privacypolicy";
import Termsconditions from "@pages/Termsconditions";
import AndroidDeleteAccount from "@pages/AndroidDeleteAccount";

const Webviewroutes = () => {
  return (
    <Routes>
      <Route
        path="/a937328b-9edf-4ffa-abbb-8ba9f5109acb"
        element={<Aboutus />}
      />
      <Route
        path="/3ed68bd6-3704-4e4a-8f63-2ba45390ea0d"
        element={<Privacypolicy />}
      />
      <Route
        path="/f839219d-a083-43b9-9ec8-85e5546084d4"
        element={<Termsconditions />}
      />
      <Route
        path="/6fafbf09-6bbc-4b39-8bbe-4ba17c3bff2a"
        element={<AndroidDeleteAccount />}
      />
    </Routes>
  );
};

export default Webviewroutes;
