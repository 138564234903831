import React from "react";

const Hideeye = () => {
  return (
    <>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.3">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.4277 8.25C11.4332 8.25 10.4793 8.64509 9.77608 9.34835C9.07282 10.0516 8.67773 11.0054 8.67773 12C8.67773 12.9946 9.07282 13.9484 9.77608 14.6517C10.4793 15.3549 11.4332 15.75 12.4277 15.75C13.4223 15.75 14.3761 15.3549 15.0794 14.6517C15.7826 13.9484 16.1777 12.9946 16.1777 12C16.1777 11.0054 15.7826 10.0516 15.0794 9.34835C14.3761 8.64509 13.4223 8.25 12.4277 8.25ZM10.1777 12C10.1777 11.4033 10.4148 10.831 10.8367 10.409C11.2587 9.98705 11.831 9.75 12.4277 9.75C13.0245 9.75 13.5968 9.98705 14.0187 10.409C14.4407 10.831 14.6777 11.4033 14.6777 12C14.6777 12.5967 14.4407 13.169 14.0187 13.591C13.5968 14.0129 13.0245 14.25 12.4277 14.25C11.831 14.25 11.2587 14.0129 10.8367 13.591C10.4148 13.169 10.1777 12.5967 10.1777 12Z"
            fill="black"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.4277 3.25C7.91373 3.25 4.87273 5.954 3.10773 8.247L3.07673 8.288C2.67673 8.807 2.30973 9.284 2.06073 9.848C1.79373 10.453 1.67773 11.112 1.67773 12C1.67773 12.888 1.79373 13.547 2.06073 14.152C2.31073 14.716 2.67773 15.194 3.07673 15.712L3.10873 15.753C4.87273 18.046 7.91373 20.75 12.4277 20.75C16.9417 20.75 19.9827 18.046 21.7477 15.753L21.7787 15.712C22.1787 15.194 22.5457 14.716 22.7947 14.152C23.0617 13.547 23.1777 12.888 23.1777 12C23.1777 11.112 23.0617 10.453 22.7947 9.848C22.5447 9.284 22.1777 8.807 21.7787 8.288L21.7467 8.247C19.9827 5.954 16.9417 3.25 12.4277 3.25ZM4.29773 9.162C5.92573 7.045 8.57773 4.75 12.4277 4.75C16.2777 4.75 18.9287 7.045 20.5577 9.162C20.9977 9.732 21.2537 10.072 21.4227 10.454C21.5807 10.812 21.6777 11.249 21.6777 12C21.6777 12.751 21.5807 13.188 21.4227 13.546C21.2537 13.928 20.9967 14.268 20.5587 14.838C18.9277 16.955 16.2777 19.25 12.4277 19.25C8.57773 19.25 5.92673 16.955 4.29773 14.838C3.85773 14.268 3.60173 13.928 3.43273 13.546C3.27473 13.188 3.17773 12.751 3.17773 12C3.17773 11.249 3.27473 10.812 3.43273 10.454C3.60173 10.072 3.85973 9.732 4.29773 9.162Z"
            fill="black"
          />
          <line
            x1="2.77925"
            y1="2.40379"
            x2="23.0112"
            y2="20.8501"
            stroke="black"
            stroke-width="1.2"
          />
        </g>
      </svg>
    </>
  );
};

export default Hideeye;
